import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import style from './styles/LoginIndex.module.css'
import { sha256 } from 'js-sha256';
import axios from 'axios';
import LoadingSpinner from '../Spinner/Spinner'; // 引入loading 轉圈圈元件
import keypair from "keypair"; // 金鑰產生元件
import nodeRSA from "node-rsa"; // RSA加密元件

function LoginIndex(props) {

  const {setSheet}=props;
  const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
  const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)

  const passwordRef = useRef(null); // 密碼參照欄位

  const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
  const [myform, setMyform] = useState({}); // 設定表單資料
  const [RegxpError, setRegxpError] = useState('');   // 顯示錯誤訊息
  const [userStatus, setUserStatus] = useState('login'); // 使用者要註冊或登入
  const [passwordVeriStatus, setPasswordVeriStatus] = useState(false); // 檢查使用者輸入的密碼與確認密碼，若驗證通過，則啟用下一步按鈕
  const [gotoSetEmail, setGotoSetEmail] = useState(false); // 點擊下一步觸發設定Email表單，前往設定註冊Email

  /***** 註冊功能State *****/
  const [hasSendSMS, setHasSendSMS] = useState(false); // 檢查是否已發送驗證碼 
  const [verifySMSBtn, setVerifySMSBtn] = useState(true); // 驗證簡訊按鈕生效
  const [showRepassword, setShowRepassword] = useState(false); // 確認密碼是否顯示
  const cipher = new nodeRSA({ b: 512 }); // RSA加密元件

  const uaaApiURL = process.env.REACT_APP_UAA_APIURL // UAA API URL

  /******************** 表單註冊Function *********************/
  /* 時時記錄表單欄位值 */
  const changeFields = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerEmail: '' });
    const id = event.target.id;
    const val = event.target.value;
    // // console.log({ id, val });
    setMyform({ ...myform, [id]: val });
    setRegxpError('');
  }

  /* 監聽密碼，密碼長度不小於8個，且包含英數字 */
  const listenPassword = (event) => {
    event.preventDefault();
    setRegxpError(''); // 清空錯誤訊息 
    const id = event.target.id;
    const val = event.target.value;
    if( val.length < 8 ) {
        setShowRepassword(false);
        setPasswordVeriStatus(false);
        setGotoSetEmail(false);
        setRegxpError('密碼長度不足（請輸入8～20位英數字）！');
    } else if( ! val.match(/[a-zA-Z]/g) || ! val.match(/[0-9]/g) ) {
        setShowRepassword(false);
        setPasswordVeriStatus(false);
        setGotoSetEmail(false);
        setRegxpError('密碼必須包含英數字！');
    } else if ( val.length > 20 ) {
        setShowRepassword(false);
        setPasswordVeriStatus(false);
        setGotoSetEmail(false);
        setRegxpError('密碼過長（請輸入8～20位英數字）！');
    } else {
        setShowRepassword(true);
        setPasswordVeriStatus(false);
        setGotoSetEmail(false);
        setRegxpError('');
    }
  }
  /* 監聽確認密碼，當密碼字元與確認密碼一致，則通過驗證 */
  const listenRepassword = (event) => {
    event.preventDefault();
    setRegxpError(''); // 清空錯誤訊息 
    const id = event.target.id;
    const val = event.target.value;
    if( val.length === myform.registerPassword.length ) {
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      verifyPassword( myform.registerPassword, val );
    } else {
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼與確認密碼不一致！');
    }
  }
    
  /* 檢查確認密碼是否與密碼一致 */
  const verifyPassword = ( RegisterPassword, RegisterRepassword ) => {
    setRegxpError(''); // 點選按鈕清空錯誤訊息 
    if( RegisterPassword === RegisterRepassword ) {
      setPasswordVeriStatus(true);
      setRegxpError('✔ 可使用此密碼');
    } else {
      setPasswordVeriStatus(false);
      setGotoSetEmail(false);
      setRegxpError('密碼與確認密碼不一致！');
    }
  }

  /* 清除殘留於表單的密碼 */
  const clearMyformRegisterData = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerRepassword: '' });
    setMyform({ ...myform, registerEmail: '' });
  }
  //
  /* 取得加密公鑰 */
  // const getPkey = async () => {
  //   let pkeyID = '';
  //   let pkey = '';
  //   let pkeyData = {};
  //   fetch('https://' + uaaApiURL + '/api/uaa/auth/public_key', {
  //     method: 'GET',
  //     // body: JSON.stringify(data),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // 'Access-Control-Allow-Origin': '*',
  //       // 'Authorization': BearerToken
  //     },
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     // // console.log('result', result);
  //     if ( result.statusCode === 200 ) {
  //       pkeyID = result.data.id;
  //       pkey = result.data.key;
  //       pkeyData = { pkeyID, pkey }
  //       // console.log( 'pkeyID', pkeyID );
  //       // console.log( 'pkey', pkey );
  //       // console.log( 'pkeyData1', pkeyData );
  //       return pkeyData;
  //     } else {
  //       setRegxpError('伺服器錯誤，請稍候再試！');
  //       // console.log( 'result.status!==200');
  //       return false;
  //     }
  //   })
  //   .catch((error) => {
  //     setRegxpError('伺服器錯誤，請稍候再試！');
  //     // console.log(error);
  //     return false;
  //   }); // END Fetch
  // }

  /* 加密密碼 */
  // const encryptPassword = async ( pwd, pkey ) => {
  //   const encryptedText = encryptRsa.encryptStringWithRsaPublicKey({ 
  //     text: pwd,   
  //     pkey,
  //   });
  //   // console.log('encryptedText', encryptedText);
  //   return encryptedText;
  // }

  /* 儲存待傳出的密碼 */
  // const setPassword = async ( event ) => {
  //   let pubkeyData = null;
  //   let pubkey = '';
  //   let encryptPWD = '';
  //   event.preventDefault();
  //   if( passwordVeriStatus ) {
  //     if( !! passwordRef.current ) {
  //       pubkeyData = await getPkey();
  //       // console.log( 'pubkeyData', pubkeyData );
  //       if( !! pubkeyData ) {
  //         pubkey = pubkeyData.pkey;
  //         encryptPWD = await encryptPassword( passwordRef.current.value, pubkey );
  //         if( !! encryptPWD ) {
  //           window.localStorage.setItem( 'vup', passwordRef.current.value );
  //         } else {
  //           setRegxpError('錯誤，密碼加密失敗！');
  //         }
  //       } else {
  //         setRegxpError('錯誤，缺少金鑰，無法加密此密碼！');
  //       }
  //     } else {
  //       setRegxpError('錯誤，密碼無法送出！');
  //     }
  //   } else {
  //     setRegxpError('密碼未通過驗證！');
  //   }
  // }

  /* 儲存待傳出的密碼 */
  // const setPassword = ( event ) => {
  //   let pubkeyData = null;
  //   let pubkey = '';
  //   let encryptPWD = '';
  //   event.preventDefault();
  //   if( passwordVeriStatus ) {
  //     if( !! passwordRef.current ) {
  //       const promise = new Promise((resolve, reject) => {
  //         pubkeyData = getPkey();
  //         // console.log( 'pubkeyData2', pubkeyData );
  //         if( !! pubkeyData ) {
  //           resolve(pubkeyData);
  //         }
  //       });
  //       promise.then((pubkeyData) => {
  //         // console.log('pubkeyData3', pubkeyData);
  //         if( !! pubkeyData ) {
  //           pubkey = pubkeyData.pkey;
  //           encryptPWD = encryptPassword( passwordRef.current.value, pubkey );
  //           if( !! encryptPWD ) {
  //             window.localStorage.setItem( 'vup', passwordRef.current.value );
  //           } else {
  //             setRegxpError('錯誤，密碼加密失敗！');
  //           }
  //         } else {
  //           setRegxpError('錯誤，缺少金鑰，無法加密此密碼！');
  //         }
  //       });
  //     } else {
  //       setRegxpError('錯誤，密碼無法送出！');
  //     }
  //   } else {
  //     setRegxpError('密碼未通過驗證！');
  //   }
  // }

    /* 加密並儲存待傳出的密碼 */
    const setPassword = ( event ) => {
      event.preventDefault();
      if( passwordVeriStatus ) {
        if( !! passwordRef.current ) {
            // 加密密碼
            const cipher = new nodeRSA({ b: 512 }); // 加密物件
            const keys = keypair({ bits: 512 }); // 公私鑰物件
            const pkey = keys.public;
            const skey = keys.private;
            const tder = skey.replace('-----BEGIN RSA PRIVATE KEY-----', '').replace('-----END RSA PRIVATE KEY-----', '');
            window.localStorage.setItem( 'tder', tder );
            // // console.log('tder',tder);
            cipher.importKey( pkey, "pkcs1-public" ); // 公鑰放入加密物件
            const encryptPwd = cipher.encrypt( JSON.stringify(passwordRef.current.value), "base64" );
            if( !! encryptPwd ) {
              window.localStorage.setItem( 'vup', encryptPwd );
              // console.log("encryptPwd: ", encryptPwd);
              // 測試解密
              // const rtder = '-----BEGIN RSA PRIVATE KEY-----' + tder + '-----END RSA PRIVATE KEY-----';
              // const decipher = new nodeRSA( rtder, "pkcs1" ); 
              // const decryptPwd = JSON.parse( decipher.decrypt(encryptPwd, "utf8") );
              // // console.log( "decryptPwd: ", decryptPwd );
            } else {
              setRegxpError('錯誤，密碼加密失敗！');
            }            
            // 加密密碼(舊)
            // let pwd = passwordRef.current.value;
            // // console.log('pwd', pwd);
            // // let encryptedText = encryptRsa.encryptStringWithRsaPublicKey({ text: pwd, pkey });
            // let pkeytest = '-----BEGIN RSA PUBLIC KEY-----MIIBCgKCAQEAu2LuNnUKl3In6z5ev/BA7WK/fnGJXy6DV6FtkjLxTC9wPeA9RvjJEVGKqABHgPNe/uUyHltsX3WF3IwQCIIB5iw+9soJEwjJ6pn2SP2TXX6YHLG/bcfOpBKxvmMYjsJmb6+kbE+OOiXz5WQAvnmsCJlH5qSrma0BE3LWUtQkC131sz9+iEVIHVk5ow5XRacOYhZNpeHdHc6jdj3EzPa95QGBW1YFxGCt+pHjLjGuUhsj5x4tVW+ZdITXEqvmBcniZ7dSibS8nW0ydSt7OtNrmdvxOSkKIsbTIVUFjzxU6DyrquuS2oOJ2+/Poxqi2EP634OrBSQ/otSydI5zdi58kwIDAQAB-----END RSA PUBLIC KEY-----';
            // let encryptedText = encryptRsa.encryptStringWithRsaPublicKey({ text: '1qaz2wsx', pkeytest });
            // // console.log('encryptedText', encryptedText);
            // if( !! encryptedText ) {
            //   window.localStorage.setItem( 'vup', encryptedText );
            // } else {
            //   setRegxpError('錯誤，密碼加密失敗！');
            // }
        } else {
          setRegxpError('錯誤，密碼無法送出！');
        }
      } else {
        setRegxpError('密碼未通過驗證！');
      }
    }

  /******************** END 表單註冊Function *********************/

  return (
    <>
    <Row>
      <Col>
          <Form className={style.form}>
          <Col className="mb-4">
            <div className={style.KidProLoginTitleBox}>
              <span className={style.KidProLoginTitle}>註冊 新會員</span>
            </div>
          </Col>
          <Col>
            <Form.Group className="mb-4">
              <Form.Label className={style.pdsLable}>輸入密碼</Form.Label>
              <input className={style.inputinfiled} type="password" id="registerPassword" name='registerPassword' maxlength="30" onChange={(event)=>{changeFields(event);listenPassword(event);}}  ref={passwordRef}  value={myform.registerPassword} required/>          
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-4">
              { !! showRepassword ? (
                <>
                  <Form.Label className={style.pdsLable}>再次輸入密碼</Form.Label>
                  <input className={style.inputinfiled}  type="password" id="registerRepassword" name='registerRepassword' maxlength="30" onChange={listenRepassword} value={myform.registerRepassword} required/>      
                </>
              ) : (
                <></>
              )}
            </Form.Group>
          </Col>        
          <Col>
            <div className={style.RegxpErrorSec}>
              <p className={style.RegxpError}>{RegxpError}</p>
            </div>
          </Col>
          <Col className="mb-4">
          { passwordVeriStatus ? (
            <div className={style.loginbuttosec}>
                <button type="button" className={style.nextStepButton} onClick={ (event) => { 
                    // setGotoSetEmail(true);
                    // setGotoSetPassword(false);
                    setSheet('registerEmail');
                    setShowRepassword(false);
                    setRegxpError('');
                    clearMyformRegisterData(event);
                    setPassword(event);
                }}>下一步</button>
            </div>            
          ) : (
            <div className={style.loginbuttosec}>
              <button type="button" className={style.nextStepButton} disabled>下一步</button>
            </div>
          ) }
          </Col>          
          {/* <Col className="mb-1">
            <div className={style.directSec}>
              <p className={style.regSec} onClick={ () => {
                setSheet('registerPhone');
                // setUserStatus( 'register' ); 
                // setLoginWay( false );
                setsmsveristatus(false);
                setHasSendSMS(false);
                // setGotoSetPassword( false );
                setShowRepassword(false);
                setPasswordVeriStatus( false );
                // setGotoSetEmail( false );  
                setRegxpError(''); 
                }}>
                <span>返回手機驗證</span>
              </p>
            </div>
          </Col>           */}
          </Form>          
      </Col>
    </Row>
    </>
  )
}

export default LoginIndex;