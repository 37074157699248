import React, { useState, useContext, createContext, useEffect, useRef } from 'react'
import AuthContext from './components/AuthContext';
import style from './styles/Login.module.css';
import './styles/antStyle.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LoadingSpinner from '../Spinner/Spinner';
import SpinnerFullScreen from '../Spinner/SpinnerFullScreen';
import Modal from 'react-bootstrap/Modal'
import popupStyle from './styles/popup.module.css';
import LoginParent from './LoginParent';
import 'bootstrap/dist/css/bootstrap.min.css'
import * as Scroll from 'react-scroll';
import { Element, Events, animateScroll as scroll,  scroller } from 'react-scroll'
import liff from '@line/liff'; // liff SDK
import jwt_decode from "jwt-decode";

/***  antd ***/
import { Steps, Typography, Layout, Collapse, Button as AntButton } from 'antd';
import './styles/antStyle.css';


function Login() {
  const {authorized, email, setEmail, auth, setAuth, userNormalLoggedIn, setUserNormalLoggedIn, addFriendStatus, setAddFriendStatus} = useContext(AuthContext);
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(''); // 設定login token
  const [hasRunLineLoginAddFriendProcessAsync, setHasRunLineLoginAddFriendProcessAsync] = useState(false); // 是否已完成LIFF所有身分驗證流程
  const [isLineBrowserStatus, setIsLineBrowserStatus] = useState(false); // 是否是LINE Browser
  const [showLineBindForm, setShowLineBindForm] = useState(false); // 是否顯示綁定流程表單
  // const [hasCheckedFriend, setHasCheckedFriend] = useState(false); // 是否已檢查過好友

  let checkFriendstatusTimeout = null; // 時時檢查好友狀態interval ID
  let line_liff_loginData = {};
  let LIFF_STORE = window.localStorage.getItem('LIFF_STORE')
  let LIFF_STOREregister = window.localStorage.getItem('LIFF_STOREregister')
  const lineAccessToken = !! window.localStorage.getItem('lat') ? window.localStorage.getItem('lat') : '';
  const loginToken = window.localStorage.getItem('loginToken') ?? ''; // 記錄是否已LINE登入
  const lineBindRedir = process.env.REACT_APP_LINE_BIND_REDIR + loginToken;
  const userNormalLoggedInStorage = window.localStorage.getItem('userNormalLoggedIn'); // 知道使用者是否先前是用一般登入
  const addFriend = window.localStorage.getItem('addFriend') ?? ''; // 記錄是否已加入官方帳號
  const lineid = process.env.REACT_APP_LINE_APP_ID;
  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const microservApiURL = process.env.REACT_APP_MICROSERV_APIURL; // microserve API URL
  const kidprologinURL = process.env.REACT_APP_LINE_BIND_REDIR // KidPro 登入導向URL
  const uaaApiURL = process.env.REACT_APP_UAA_APIURL // UAA API URL
  const uaaApiURLTest = process.env.REACT_APP_UAA_APIURL_TEST // Test UAA API URL

  /*** 20230928 自動LINE登入，載入頁面時直接記錄使用者是點選LINE登入 ***/
  useEffect(() => {
    window.localStorage.setItem('LIFF_STOREregister', true);
    LIFF_STOREregister = 'true';
  }, [])

  // /***** UTM(舊版) *****/
  // /*** Cookie設定 ***/
  // const [cookies, setCookie] = useCookies(['utm']);
  // // console.log('cookies._ga',cookies._ga);
  // /*** END Cookie設定 ***/

  // /*** GA4設定 ***/
  // const GA4ID = process.env.REACT_APP_GA4_ID;
  // const GA4Name = process.env.REACT_APP_GA4_NAME;
  // // console.log('GA4ID',GA4ID);
  // /* Ga4初始化 */
  // try{
  //   // const cid = 'mkc-' + Math.floor( 1000000000 * Math.random() );
  //   // setCookie( 'cid', cid );
  //   ReactGA.initialize( GA4ID, {
  //     gaOptions: {
  //       name: GA4Name,
  //       clientId: cookies._ga,
  //     }
  //   });
  //   // Send pageview with a custom path
  //   // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  //   // ReactGA.send({ hitType: "scrolls", page: window.location.pathname });
    
  //   // ReactGA.event('login');
    
  //   // Send a custom event
  //   // ReactGA.event({
  //   //   category: "testCategory",
  //   //   action: "testAction",
  //   //   label: "testLabel",
  //   // });
  //   // console.log(window.location.pathname);
  //   // console.log(ReactGA);
  // }catch(e){
  //   console.log(e);
  // }
  // /* 初始化只在渲染時執行一次 */
  // // useEffect(() => {
  // //   Ga4Initial();
  // // }, []);
  // /*** END GA4設定 ***/
  // /***** END UTM *****/  

  /*** Conversion API ***/
  const conversionApiViewPage = async () => {
    if( process.env.REACT_APP_UTM === "production" ) {
      let BearerToken = undefined;
      if (loginToken) {
        BearerToken = 'Bearer ' + loginToken;
      }
      try {
        const response = await fetch('https://' + microservApiURL + '/view-content?sourceUrl=' + encodeURI(window.location.href), {
          method: 'GET',
          credentials: "include",
          headers: {
            'Authorization': BearerToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Request failed: conversionApiViewPage()');
        }
        const result = await response.json();
        // // // // console.log(result)
        if (result.success) {
          const eventID = result?.trackerData?.eventId;
          return eventID;
        } else {
          throw new Error('Request failed: conversionApiViewPage()');
          // return '';
        }
      } catch (error) {
        console.log('ConversionApiViewPage Error');
        // throw new Error('ConversionApiViewPage Error: ', error);
        // return '';
      }
    } else {
      return "";
    }
  }
  
  /*** 取得登入者資料 ***/
  // const getUserData = async() => {
  //   const decoded = jwt_decode(loginToken);
  // console.log(decoded);
  //   const uid = decoded?.id || "";
  //   const email = decoded?.email || "";
  //   const atIndex = email.indexOf('@');
  //   const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : "";
  //   // // // // // // // // console.log(adminUsername);
  //   // setUserId(uid);
  //   return uid;
  // }
  // useEffect(() => {
  //   getUserData();
  // }, []);
  // useEffect(() => {
  //   getUserData();
  // }, [loginToken]);

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('smsVeriStatus');
      window.localStorage.removeItem('phoneNumber');
      window.localStorage.removeItem('url');
    // }, false);
  }

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = async(event) => {
    event?.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
          window.FB.logout(function (response) {
            // // // // // // // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    setNOuaabindLine(false);
    setEmail(null);
    // 20230802 註解，避免自動登出
    localStorage.clear(); 
    // setTimeout(()=>{
    //   setSheet('LoginIndex');
    // }, 3000);
  }

  // 設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);


  /* 使用者若是點LINE註冊(有綁定LINE帳號會自動登入)，檢驗使用者是否已綁定過LINE。 (LINE登入走LoginIndex->LoginEncode->CheckActivity->導向KidPro官網登入－> ( 1.有JWT: 回CheckActivity檢驗活動狀態－>導向首頁/submitpage/countingpage ) ( 2.無JWT： 導向首頁繼續下方判斷 ) */
  /* 
   * liffLogin自動登入只有在Liff Browser才會執行 
   * v1. 開始進入畫面，不彈出視窗(loginToken == null、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage==null、email==null)
   * v4. 未綁定，一開始就點LINE註冊(loginToken == null、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-1. 未綁定，一開始就點LINE註冊，LINE Login Token綁定舊帳號(用Wordpress登入)，關閉視窗(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-1-1. 登入成功，關閉視窗，同4-3.
   *  v4-2. 未綁定，一開始就點LINE註冊，LINE Login Token註冊Wordpress新帳號(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="null"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-1. 註冊成功，要求進入登入畫面(此處setSheet由RegisterEmail控制)(loginToken == null、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-2. 登入成功，關閉視窗.(loginToken == "字串"、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-3. 已綁定，一開始就點LINE註冊，LINE Login Token直接登入(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   * v6. 未綁定直接點選LINE登入，輸入wordpress帳號後登入，關閉視窗，同4-1. 4-2.
   * v7. 已綁定直接點選LINE登入，直接登入，關閉視窗，同4-3. 4-1-1.
   * 8. 其他狀況，回login index，預設關閉視窗
   */
  const login = async (count) => {
    try {
      // // console.log('linelogin');
      // alert(`1. login: ${count}`)
      const isLineBrowserBool = await isLineBrowser();
      // alert(`2. isLineBrowserBool: ${isLineBrowserBool}`)
      if( isLineBrowserBool ) {
        await liffLogin(count);
      } else { 
        await lineLogin(count);
      }
    } catch(error) {
      // alert(`20.  login error: ${error}`)
      if( count < 3 ) { // 最多重複登入三次
        // alert(`20-1. count9: ${count}`)
        await login(++count);
      } else { // 超過三次導向外部瀏覽器登入
        // alert(`20-2. count10: ${count}`)
        alert("LINE登入失敗，請以手機或Email進行登入！")
        window.location.href = "/?openExternalBrowser=1";
      }
    }
  }
  // Liff登入
  const liffLogin = (count) => {
    // const liffLoginTO = await setTimeout( async() => { // 一秒後才執行，並免KidPro Logo無法載入
    return new Promise((resolve)=>{ // 一秒後才執行，並免KidPro Logo無法載入
      setTimeout(async()=>{
        if( ! count && ! Number.isInteger(count) ) {
          count = 0;
        }
        try {
          const init = await liff.init({
            liffId: lineid, // Use own liffId
            // withLoginOnExternalBrowser: true // Enable automatic login process at External Browser
          })
          .then(async()=>{
            // alert(`2-2. liff: ${JSON.stringify(liff)}`)
            const isLoggedIn = liff.isLoggedIn();
            const liffLoginRedir = "https://" + backendURL + "/LoginEncode"; 
            // const liffLoginRedir = "https://liff.line.me/" +  lineid + "?page=SubmitPage";
            if( ! isLoggedIn ) {
              // alert(`3. count1: ${count}`)
              if( count < 3 ) { // 最多重複登入三次
                // alert(`4. count2: ${count}`)
                count++;
                
                /* 解決LIFF Login非同步問題(正確版) */
                const liffLoginAsync = () => {
                  return new Promise(() => {
                    liff.login({ redirectUri: liffLoginRedir });
                  })
                }
                await liffLoginAsync();
              } else { // 超過三次導向外部瀏覽器登入
                // alert(`5. count3: ${count}`)
                alert("LINE登入失敗，請以手機或Email進行登入！")
                window.location.href = "/?openExternalBrowser=1";
              }
              // liff.login();
            } else { 
              const IDToken = liff.getIDToken();
              // alert(`6. accessToken: ${accessToken}`)
              line_liff_loginData = {
                IDToken,
              }
              try {
                const response1 = await fetch('https://' + uaaApiURL + '/api/uaa/line_liff_login', {
                  method: 'POST',
                  body: JSON.stringify(line_liff_loginData),
                  headers: {
                    'Content-Type': 'application/json'
                  },
                })
                // alert(`6-1. line_liff_login response1: ${JSON.stringify(response1)}`)
                // if ( ! response1.ok ) {
                //   throw new Error('Request failed: line_liff_login');
                // }
                const result1 = await response1.json();
                // alert(`7. line_liff_login result1: ${JSON.stringify(result1)}`)
                // if (result1.success) {
                if( result1.status === 200 ) { // 1. LINE登入/註冊成功
                  // console.log('1. LINE登入/註冊成功');
                  // alert(`8. LINE登入/註冊成功`)
                  const loginToken = await result1?.data?.loginToken || "";
                  // alert(`9. loginToken: ${loginToken}`)
                  window.localStorage.setItem( 'lineRegisterLoginToken', loginToken ); // 此loginToken可能為未綁定的loginToken，只做記錄，不登入
                  const BearerToken = 'Bearer ' + loginToken;
                  try{
                    const response2 = await fetch('https://' + uaaApiURL + '/api/uaa/user_pofile', {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': BearerToken
                      }
                    });
                    // alert(`9-1. line_liff_login response2: ${JSON.stringify(response2)}`)
                    // if ( ! response2.ok ) {
                    //   throw new Error('Request failed: user_pofile');
                    // }
                    const result2 = await response2.json();
                    // alert(`10. line_liff_login result2: ${JSON.stringify(result2)}`)
                    // if (result2.success) {
                    const wpEmail = await result2?.data?.email || "";
                    window.localStorage.setItem('wpEmail', wpEmail);
                    // alert(`11. wpEmail: ${wpEmail}`)
                    if(wpEmail) { // 2. Wordpress帳號已存在(已綁定LINE)，直接登入
                      // console.log('2. Wordpress帳號已存在(已綁定LINE)，直接登入');
                      // liffLineBind = true;
                      window.localStorage.setItem('liffLineBind', true);
                      // liffHasLineBound = true; // 記錄已綁定
                      window.localStorage.setItem('liffHasLineBound', true);
                      // alert(`12. 2. Wordpress帳號已存在(已綁定LINE)，直接登入`)
                      window.localStorage.setItem('loginToken', loginToken);
                      // setShowLineBindForm(false);
                    } else { // 3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)
                      // console.log('3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)');
                      // alert(`13. 3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)`)
                      // await setSheet('NOuaabindLine');
                      window.localStorage.setItem( 'LIFF_STOREregister', true );
                      // alert(`13-1. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                      // setLiffLineBind(true);
                      // liffLineBind = true;
                      window.localStorage.setItem('liffLineBind', true);
                      // liffHasLineBound = false;
                      window.localStorage.setItem('liffHasLineBound', false);
                      // const promisedSetLiffLindBind = (newState) => new Promise((resolve) => setLiffLineBind(newState, resolve));
                      // await promisedSetLiffLindBind(true); // 此時會無限等待setState完成，必須在之後放一個包含return的function以結束setState操作循環
                      // await new Promise((resolve) => setTimeout(resolve, 0)); // 等待下一个事件循环周期
                      // alert(`13-2. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                      // setShowLineBindForm(true);
                    }
                    // }
                  } catch(error) {
                    // alert(`14. Error: get_user_pofile error！ ${error}`);
                  }
                } else { // 4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定
                  // console.log('4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定');
                  // alert(`15. 4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定`)
                  // await setSheet('NOuaabindLine');
                  window.localStorage.setItem( 'LIFF_STOREregister', true );
                  // alert(`15-1. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                  // alert(`15-2. liffHasLineBound: ${window.localStorage.getItem('liffHasLineBound')}`)
                  // setLiffLineBind(true); // 已經設定，但需要用useEffect才算完整執行
                  // liffLineBind = true;
                  window.localStorage.setItem('liffLineBind', true);
                  // liffHasLineBound = false;
                  window.localStorage.setItem('liffHasLineBound', false);
                  // const promisedSetLiffLindBind = (newState) => new Promise((resolve) => setLiffLineBind(newState, resolve));
                  // await promisedSetLiffLindBind(true); // 此時會無限等待setState完成，必須在之後放一個包含return的function以結束setState操作循環
                  // await new Promise((resolve) => setTimeout(resolve, 0)); // 等待下一个事件循环周期
                  // alert(`15-3. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                  // alert(`15-4. liffHasLineBound: ${window.localStorage.getItem('liffHasLineBound')}`)
                  // setTimeout(()=>{
                  // setShowLineBindForm(true);
                  // }, 3000);
                }
                // }
                // else {
                //   throw new Error('Request failed: line_liff_login');
                // }
              } catch(error) {
                // console.log(error);
                // alert(`16-0.  login error: ${error}`)
                // alert(`16. count4: ${count}`)
                if( count < 3 ) { // 最多重複登入三次
                  // alert(`17. count5: ${count}`)
                  await login(++count);
                } else { // 超過三次導向外部瀏覽器登入
                  // alert(`18. count6: ${count}`)
                  alert("LINE登入失敗，請以手機或Email進行登入！")
                  window.location.href = "/?openExternalBrowser=1";
                }
              }
            }
          });
        } catch(error) {
          // alert(`19.  liff_init error: ${error}`)
          if( count < 3 ) { // 最多重複登入三次
            // alert(`19-1. count7: ${count}`)
            await login(++count);
          } else { // 超過三次導向外部瀏覽器登入
            // alert(`19-2. count8: ${count}`)
            alert("LINE登入失敗，請以手機或Email進行登入！")
            window.location.href = "/?openExternalBrowser=1";
          }
        }
        resolve(true);
      }, 300);
    });
  }

  /*** 外部瀏覽器自動LINE登入 ***/
  const lineLogin = (count) => {
    return new Promise((resolve)=>{
      setTimeout(async()=>{ // 一秒後才執行，並免KidPro Logo無法載入
        if( ! count && ! Number.isInteger(count) ) {
          count = 0;
        }
        try {
          const init = await liff.init({
            liffId: lineid, // Use own liffId
            // withLoginOnExternalBrowser: true // Enable automatic login process at External Browser
          })
          .then(async()=>{
            const isLoggedIn = liff.isLoggedIn();
            const liffLoginRedir = "https://" + backendURL + "/LoginEncode"; 
            if( ! isLoggedIn ) {
              // alert(`3. count1: ${count}`)
              if( count < 3 ) { // 最多重複登入三次
                // alert(`4. count2: ${count}`)
                count++;
                
                /*** 解決LIFF Login非同步問題(正確版) ***/
                const liffLoginAsync = () => {
                  return new Promise(() => {
                    liff.login({ redirectUri: liffLoginRedir });
                  })
                }
                await liffLoginAsync();
              } else { // 超過三次導向外部瀏覽器登入
                // alert(`5. count3: ${count}`)
                alert("LINE登入失敗，請以手機或Email進行登入！")
                window.location.href = "/?openExternalBrowser=1";
              }
              // liff.login();
            } else { 
              /* 在LINE web view自動登入 */
              const IDToken = liff.getIDToken();
              // alert(`6. accessToken: ${accessToken}`)
              line_liff_loginData = {
                IDToken,
              }
              try {
                const response1 = await fetch('https://' + uaaApiURL + '/api/uaa/line_liff_login', {
                  method: 'POST',
                  body: JSON.stringify(line_liff_loginData),
                  headers: {
                    'Content-Type': 'application/json'
                  },
                })
                const result1 = await response1.json();
                // alert(`7. line_liff_login result1: ${JSON.stringify(result1)}`)
                if( result1.status === 200 ) { // 1. LINE登入/註冊成功
                  // console.log('1. LINE登入/註冊成功');
                  // alert(`8. LINE登入/註冊成功`)
                  const loginToken = await result1?.data?.loginToken || "";
                  // alert(`9. loginToken: ${loginToken}`)
                  window.localStorage.setItem( 'lineRegisterLoginToken', loginToken ); // 此loginToken可能為未綁定的loginToken，只做記錄，不登入
                  const BearerToken = 'Bearer ' + loginToken;
                  try{
                    const response2 = await fetch('https://' + uaaApiURL + '/api/uaa/user_pofile', {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': BearerToken
                      }
                    });
                    // alert(`9-1. line_liff_login response2: ${JSON.stringify(response2)}`)
                    const result2 = await response2.json();
                    // alert(`10. line_liff_login result2: ${JSON.stringify(result2)}`)
                    const wpEmail = await result2?.data?.email || "";
                    // alert(`11. wpEmail: ${wpEmail}`)
                    if(wpEmail) { // 2. Wordpress帳號已存在(已綁定LINE)
                      // console.log('2. Wordpress帳號已存在(已綁定LINE)');
                      // liffLineBind = true;
                      window.localStorage.setItem('liffLineBind', true);
                      // liffHasLineBound = true; // 記錄已綁定
                      window.localStorage.setItem('liffHasLineBound', true);
                      const wpLoginEmail = window.localStorage.getItem('wpLoginEmail');
                      if( wpEmail === wpLoginEmail ) { // 2-1. Wordpress帳號已存在(已綁定LINE)，與前一階段一般登入的email相同，直接登入
                        // alert(`12-1. Wordpress帳號已存在(已綁定LINE)，與前一階段一般登入的email相同，直接登入。 wpEmail: ${wpEmail}, wpLoginEmail: ${wpLoginEmail}`)
                        window.localStorage.setItem('loginToken', loginToken);
                        // setShowLineBindForm(false);
                      } else { // 2-2. Wordpress帳號已存在(已綁定LINE)，與前一階段一般登入的email不同，進入帳號已存在視窗
                        // alert(`12-2. Wordpress帳號已存在(已綁定LINE)，與前一階段一般登入的email不同，進入帳號已存在視窗。 wpEmail: ${wpEmail}, wpLoginEmail: ${wpLoginEmail}`)
                        window.localStorage.setItem('AccountExistNotify', true);
                      }
                    } else { // 3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)
                      // console.log('3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)');
                      // alert(`13. 3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)`)
                      // await setSheet('NOuaabindLine');
                      window.localStorage.setItem( 'LIFF_STOREregister', true );
                      // alert(`13-1. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                      // setLiffLineBind(true);
                      // liffLineBind = true;
                      window.localStorage.setItem('liffLineBind', true);
                      // liffHasLineBound = false;
                      window.localStorage.setItem('liffHasLineBound', false);
                    }
                  } catch(error) {
                    // alert(`14. Error: get_user_pofile error！ ${error}`);
                  }
                } else { // 4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定
                  // console.log('4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定');
                  // alert(`15. 4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定`)
                  // await setSheet('NOuaabindLine');
                  // alert(`15-1. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                  // alert(`15-2. liffHasLineBound: ${window.localStorage.getItem('liffHasLineBound')}`)
                  window.localStorage.setItem( 'LIFF_STOREregister', true );
                  // setLiffLineBind(true); // 已經設定，但需要用useEffect才算完整執行
                  // liffLineBind = true;
                  window.localStorage.setItem('liffLineBind', true);
                  // liffHasLineBound = false;
                  window.localStorage.setItem('liffHasLineBound', false);
                  // alert(`15-3. liffLineBind: ${window.localStorage.getItem('liffLineBind')}`)
                  // alert(`15-4. liffHasLineBound: ${window.localStorage.getItem('liffHasLineBound')}`)
                }
              } catch(error) {
                // console.log(error);
                // alert(`16-0.  login error: ${error}`)
                // alert(`16. count4: ${count}`)
                if( count < 3 ) { // 最多重複登入三次
                  // alert(`17. count5: ${count}`)
                  await login(++count);
                } else { // 超過三次導向外部瀏覽器登入
                  // alert(`18. count6: ${count}`)
                  alert("LINE登入失敗，請以手機或Email進行登入！")
                  window.location.href = "/?openExternalBrowser=1";
                }
              }
            }
          });
        } catch(error) {
          // alert(`19.  liff_init error: ${error}`)
          if( count < 3 ) { // 最多重複登入三次
            // alert(`19-1. count7: ${count}`)
            await login(++count);
          } else { // 超過三次導向外部瀏覽器登入
            // alert(`19-2. count8: ${count}`)
            alert("LINE登入失敗，請以手機或Email進行登入！")
            window.location.href = "/?openExternalBrowser=1";
          }
        }
        resolve(true);
      }, 300);
    });
  }  

  /* API立即觸發好友狀態檢查 */
  const apiCheckFriendInstant = (event) => {
    // alert(`apiCheckFriendInstant()`);
    // window.localStorage.setItem('addFriend', 'true');
    // let BearerToken = undefined;
    // if (loginToken) {
    //   BearerToken = 'Bearer ' + loginToken;
    // }
    // try {
    //   const response = await fetch('https://' + microservApiURL + '/view-content?sourceUrl=' + encodeURI(window.location.href), {
    //     method: 'GET',
    //     credentials: "include",
    //     headers: {
    //       'Authorization': BearerToken,
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   });
    //   if (!response.ok) {
    //     throw new Error('Request failed: conversionApiViewPage()');
    //   }
    //   const result = await response.json();
    //   // // // // console.log(result)
    //   if (result.success) {
    //     const eventID = result?.trackerData?.eventId;
    //     return eventID;
    //   } else {
    //     throw new Error('Request failed: conversionApiViewPage()');
    //     // return '';
    //   }
    // } catch (error) {
    //   // // console.log('ConversionApiViewPage Error');
    //   // throw new Error('ConversionApiViewPage Error: ', error);
    //   // return '';
    // }
  }

  /* API檢查好友狀態 */
  const apiCheckFriend = async(loginTokenPara) => {
      const loginToken = loginTokenPara ?? window.localStorage.getItem('loginToken');
      // alert(`27-1. apiCheckFriend(),loginToken: ${loginToken}`);
      let BearerToken = undefined;
      if (loginToken) {
        BearerToken = 'Bearer ' + loginToken;
      }
      try {
        const response = await fetch('https://' + uaaApiURLTest + '/api/uaa/user-pofile?line=true', {
          method: 'GET',
          // credentials: "include",
          headers: {
            'Authorization': BearerToken,
            'Accept': '*/*'
          }
        });
        // alert(`27-2. response: ${JSON.stringify(response)}`);
        const result = await response.json(); // 轉為javascript object
        // alert(`27-4. result: ${JSON.stringify(result)}`); // 轉為JSON格式
   
        // alert(`28. apiCheckFriend() result?.status: ${result?.status}`)
        if ( result?.status === 200 ) {
          // console.log('getFriendship', result?.friendFlag);
          // alert(`29. result?.data: ${JSON.stringify(result?.data)}`);
          // alert(`29-1. result?.data?.line: ${JSON.stringify(result?.data?.line)}`);
          if( result?.data?.line ) {
            // setIsLoadingLine(false);
            // alert(`30. Has added friend: result?.data?.line?.userId: ${result?.data?.line?.userId}`);
            setAddFriendStatus(true);
            window.localStorage.setItem('addFriend', 'true');
          } else {
            // setIsLoadingLine(false);
            // alert(`32. Has not added friend: result?.data?.line?.userId: ${result?.data?.line?.userId}`);
            // liff.getFriendship();
            // setFriendRegxpError(<span>您尚未將KidPro加入好友（請先<a href='https://line.me/R/ti/p/%40lzb1751t'>【加入KidPro好友】</a>，或將KidPro官方帳號解除封鎖）！</span>);
            setAddFriendStatus(false);
            window.localStorage.setItem('addFriend', 'false');
          }
        } else {
          throw new Error('Request failed: apiCheckFriend()');
          // return '';
        }

      } catch (error) {
        console.log(error);
        // throw new Error('ConversionApiViewPage Error: ', error);
        return;
      }
  }
  
  /* LIFF立即觸發好友狀態檢查 */
  const liffCheckFriendInstant = (event) => {
      // console.log('liffCheckFriendInstant')
      // setIsLoadingLine(true);
      // console.log('liffCheckFriendInstant-intervalLineMsg',intervalLineMsg);
      if( !! event ) {
          event.preventDefault();
      }
      liff.init({
          liffId: lineid, // Use own liffId
      })
      .then(() => {
          // 檢查LINE是否登入，若有則檢查好友狀態
          let isLoggedIn = liff.isLoggedIn();
          // // // console.log('line',isLoggedIn);
          if( isLoggedIn ) {
              liff.getFriendship().then( data => {
              // // // // console.log('getFriendship', data.friendFlag);
                  if( data.friendFlag === true ) {
                      // setIsLoadingLine(false);
                      setAddFriendStatus(true);
                      window.localStorage.setItem('addFriend', 'true');
                      alert('已加入KidPro好友！');
                      // setFriendRegxpError('已加入KidPro好友！');
                      // clearInterval(t);
                  } else {
                      // setIsLoadingLine(false);
                      liff.getFriendship();
                      setAddFriendStatus(false);
                      window.localStorage.setItem('addFriend', 'false');
                      window.location.assign('https://line.me/R/ti/p/%40lzb1751t');
                  }
              });
          }
      })
      .catch((err) => {
          // setIsLoadingLine(false);
      });
  }    

  /* LIFF檢查好友狀態 */
  // let checkFriendStatusIntervalId = null; // 好友狀態setInterval ID
  let count = 0;
  const liffCheckFriend = async() => {
    // alert(`23. liffCheckFriend: ${count}`); 
    if(count > 10) {
        count = 0;
        // clearInterval(checkFriendStatusIntervalId);
        return;
    }
    const liffInit = async() => {
      liff.init({
        liffId: lineid, // Use own liffId
      })
    }
    const liffIsLogin = async() => {
      return liff.isLoggedIn();
    }
    const liffCheckFriendship = async() => {
      await liff.getFriendship().then( data => {
        if( data.friendFlag === true ) {
            setAddFriendStatus(true);
            window.localStorage.setItem('addFriend', 'true');
        } else {
            setAddFriendStatus(false);
            window.localStorage.setItem('addFriend', 'false');
        }
      });
    }
    const liffInitPromise = () => {
      return new Promise((resolve) => {
        // alert(`24. liffInit()`)
        liffInit();
        resolve(true);
      })
    }
    const liffIsLoginPromise = () => {
      return new Promise((resolve) => {
        // alert(`25. liffIsLogin()`)
        resolve(liffIsLogin());
      })
    }
    await liffInitPromise();
    await liffIsLoginPromise().then(async(liffIsLoginPromiseResolve) => {
      // alert(`26. liffIsLoginPromise resolve: ${liffIsLoginPromiseResolve}`)
      if(liffIsLoginPromiseResolve) {
        // await liffCheckFriendshipPromise();
        await liffCheckFriendship();
      }
    });
  }

  /* 檢查使用者瀏覽器 */
  const isLineBrowser = async() => {
    const isLineBrowserBool = /Line/i.test(navigator.userAgent);
    // alert(`isLineBrowserBool: ${isLineBrowserBool}`)
    setIsLineBrowserStatus(isLineBrowserBool);
    return isLineBrowserBool;
  }

  /*** 立即檢查是否加入好友 ***/
  const checkHasAddedFriendInstant = (event) => {
      event?.preventDefault();
      if( isLineBrowser() ) {
          liffCheckFriendInstant(event);
      } else {
          apiCheckFriendInstant(event);
      }
  }

  // /*** 間隔檢查是否加入好友 ***/
  // const checkHasAddedFriend = async(loginTokenPara) => {
  //   try {
  //     // alert(`38-1. checkHasAddedFriend loginToken: ${loginToken}`)
  //     // if( window.localStorage.getItem('loginToken') ) { //20230928 註解，改未綁定也要先檢查
  //       const isLineBrowserBool = await isLineBrowser();
  //       if( isLineBrowserBool ) {
  //         await liffCheckFriend();
  //       } else {
  //         await apiCheckFriend(loginTokenPara);
  //       }
  //     // }
  //     // setHasCheckedFriend(true);
  //   } catch(error) {
  //     // alert(`38-2. checkHasAddedFriend error: ${error}`)
  //   }
  // }

  /*** 間隔檢查是否加入好友。20230928 因為未綁定無loginToken，無法用API查詢是否家好友，都改用liffCheckFriend() ***/
  const checkHasAddedFriend = async() => {
    try {
      // alert(`38-1. checkHasAddedFriend`)
      // if( window.localStorage.getItem('loginToken') ) { //20230928 註解，改未綁定也要先檢查
      await liffCheckFriend();
      // }
      // setHasCheckedFriend(true);
    } catch(error) {
      // alert(`38-2. checkHasAddedFriend error: ${error}`)
    }
  }

  /*** 20230802之後新增的版本 只要localStorage中的userNormalLoggedInStorage為true，彈出LINE登入視窗 ***/
  /** 目標：
   * v1. 開始進入畫面，不彈出視窗(loginToken == null、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage==null、email==null)
   * v2. 未綁定一般登入後，顯示LINE註冊/登入視窗(loginToken == null、Sheet == 'register'、LIFF_STOREregister==null、userNormalLoggedInStorage=="true"、email==null)
   * 3. 未綁定一般登入，LINE註冊/登入點擊後(loginToken == null、Sheet == 'register'、LIFF_STOREregister==null、userNormalLoggedInStorage=="false"、email==null)
   *  v3-1. 未綁定一般登入，LINE註冊/登入點擊後，直接綁定並登入，關閉視窗(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   *  // v3-2. 已綁定一般登入，LINE註冊/登入點擊後，LINE Login Token直接登入，關閉視窗(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email=="字串")
   * v4. 未綁定，一開始就點LINE註冊(loginToken == null、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-1. 未綁定，一開始就點LINE註冊，LINE Login Token綁定舊帳號(用Wordpress登入)，關閉視窗(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-1-1. 登入成功，關閉視窗，同4-3.
   *  v4-2. 未綁定，一開始就點LINE註冊，LINE Login Token註冊Wordpress新帳號(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="null"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-1. 註冊成功，要求進入登入畫面(此處setSheet由RegisterEmail控制)(loginToken == null、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-2. 登入成功，關閉視窗.(loginToken == "字串"、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-3. 已綁定，一開始就點LINE註冊，LINE Login Token直接登入(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   * ov5. 已綁定，一般登入後，直接儲存WP Login Token登入，關閉視窗(loginToken == "字串"、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage=="true"、email==null)
   * v6. 未綁定直接點選LINE登入，輸入wordpress帳號後登入，關閉視窗，同4-1. 4-2.
   * v7. 已綁定直接點選LINE登入，直接登入，關閉視窗，同4-3. 4-1-1.
   * 8. 其他狀況，回login index，預設關閉視窗
   */
  /**
   * 20230904 目標：插入加入LINE官方帳號流程
   * 做法：登入成功後(3-1, 4-1-1, 4-2-2, 4-3, 5)檢查是否已綁定LINE官方帳號
   * v5必須改：新增必須已綁定並加入官方帳號才不彈出LINE登入
   */
  /*** userNormalLoggedInStorage false => LINE登入成功(不彈出) ***/
  /*** userNormalLoggedInStorage 不存在 => 一般登入(不彈出) ***/
  /*** userNormalLoggedInStorage true => LINE登入(彈出) ***/
  const popupLineLogin = async() => {
    const isLineBrowserBool = await isLineBrowser();
    // alert(`39-6. isLineBrowserBool: ${isLineBrowserBool}`)
    if( isLineBrowserBool ) {
      if( window.localStorage.getItem('liffLineBind') ) { // 經過綁定流程
        try {
          if( window.localStorage.getItem('loginToken') && window.localStorage.getItem('liffHasLineBound') ) {
            // alert(`40. 已登入、已綁定，關閉視窗`) 
            setShowLineBindForm(false); // 關閉視窗，主動點選還是會顯示
            // window.location.href = lineBindRedir;
          } else if( window.localStorage.getItem('loginToken') && window.localStorage.getItem('liffHasLineBound') === 'false' ) {
            // alert(`41. 已登入、未綁定(錯誤狀態)，清除localStorage，關閉視窗`)
            localStorage.clear();
            setShowLineBindForm(true);
          } else if( ! window.localStorage.getItem('loginToken') && window.localStorage.getItem('liffHasLineBound') === 'false' ) {
            // alert(`43. 未登入、未綁定，彈出視窗`)  
            setShowLineBindForm(true);
          } else {
            // alert(`42. 未登入、已綁定，將LINE註冊時的login token存入localStorage，關閉視窗`)
            const lineRegisterLoginToken = window.localStorage.getItem('lineRegisterLoginToken');
            window.localStorage.setItem('loginToken', lineRegisterLoginToken);
            setShowLineBindForm(false);
            // window.location.href = lineBindRedir;
          }
        } catch(error) {
          // alert(`45-1. popupLineLogin: ${error}`)
          console.log(error);
        }
      }
    } else {
      try {
        // v1. 4.
        if( ! window.localStorage.getItem('loginToken') && ! LIFF_STOREregister && ! userNormalLoggedInStorage && ! window.localStorage.getItem('AccountExistNotify') ) { 
          // alert(`40. 1. 4 未登入、初始狀態`)
          setShowLineBindForm(false); // 關閉視窗，主動點選還是會顯示
          // // window.location.href = lineBindRedir;
        // v2.  
        } else if( ! window.localStorage.getItem('loginToken') && ! LIFF_STOREregister && userNormalLoggedInStorage==="true" && ! window.localStorage.getItem('AccountExistNotify') ) {
          // alert(`41. 2 未登入、先點選一般登入`)
          setShowLineBindForm(true);
        // v3-1. 4.3
        } else if( !! window.localStorage.getItem('loginToken') && LIFF_STOREregister && userNormalLoggedInStorage==="false" && ! window.localStorage.getItem('AccountExistNotify') 
        ) { 
          // alert(`42. 3-1. 4.3 已登入、先一般登入、再LINE登入`)
          setShowLineBindForm(false);
          // window.location.href = lineBindRedir;
        // v3-2.
        } else if( !! window.localStorage.getItem('loginToken')  && LIFF_STOREregister && userNormalLoggedInStorage==="true" && ! window.localStorage.getItem('AccountExistNotify') ) { 
          // alert(`43. v3-2. 一般登入、已綁定直接登入`)
          setShowLineBindForm(false);
          // window.location.href = lineBindRedir;
        // 4-1. 4-2. 6
        } else if( ! window.localStorage.getItem('loginToken') && LIFF_STOREregister && ! userNormalLoggedInStorage && ! window.localStorage.getItem('AccountExistNotify') ) {
          // alert(`44. 4-1. 4-2. 6 直接點選LINE登入、未綁定`)
          setShowLineBindForm(true);
        // 4-1-1. 4-2-2. LINE登入且已綁定
        } else if( !! window.localStorage.getItem('loginToken') && LIFF_STOREregister && ! userNormalLoggedInStorage && ! window.localStorage.getItem('AccountExistNotify') 
        ) {
          // alert(`45. 4-1-1. 4-2-2. LINE登入且已綁定`)
          setShowLineBindForm(false);
          // window.location.href = lineBindRedir;
        } else if( ! window.localStorage.getItem('loginToken') && LIFF_STOREregister && userNormalLoggedInStorage==="false" && window.localStorage.getItem('AccountExistNotify') ) { 
          // alert(`44-2. 5. 已綁定一般登入，LINE已綁訂其他帳號`)
          setShowLineBindForm(true);
        } else if( !! window.localStorage.getItem('loginToken') && ! LIFF_STOREregister && userNormalLoggedInStorage === "true" && ! window.localStorage.getItem('AccountExistNotify') ) { 
          // alert(`44-3. 5. 已綁定一般登入，將關閉視窗`)
          setShowLineBindForm(false);
          // window.location.href = lineBindRedir;
        } else { // 有登入，但沒有其他參數；已綁定一般登入
          // alert(`44-4. 5. 有登入，但沒有其他參數；已綁定一般登入`)
          setShowLineBindForm(false);
          // window.location.href = lineBindRedir;
        }
      } catch(error) {
        // alert(`45-1. popupLineLogin: ${error}`)
        console.log(error);
      }      
    }
  }

  /*** 將加入好友相關的彈出視窗獨立出來，是LIFF瀏覽器檢查是否已登入LIFF；不是LIFF瀏覽器，檢查使用者是否LINE/一般登入已綁定 ***/
  const addFriendPopup = async() => {
    const isLineBrowserBool = await isLineBrowser();
    if( isLineBrowserBool ) {
      const init = await liff.init({
        liffId: lineid, // Use own liffId
      })
      .then(async() => {
        // 檢查LINE是否登入，若有則檢查好友狀態
        let isLoggedIn = liff.isLoggedIn();
        // console.log('line',isLoggedIn);
        if( window.localStorage.getItem('loginToken') && isLoggedIn && window.localStorage.getItem('liffLineBind') && window.localStorage.getItem('liffHasLineBound') ) { // 登入並綁定才進行加入好友判斷
          if( addFriend === "true" || addFriendStatus ) {
            // alert(`47. 已加入好友，關閉視窗。`)
            setShowLineBindForm(false);
          } else if( addFriend === "false" || ! addFriendStatus ) {
            // alert(`48. 未加入好友，彈出視窗。`)
            setShowLineBindForm(true);
          } else {
            // alert(`50. addFriendPopup, Other, handleClose`)
            setShowLineBindForm(false);
          }
        }
      })
      .catch((err) => {
        count++;
        login(count);
      });
    } else {
      if( window.localStorage.getItem('loginToken') && ( window.localStorage.getItem('externalBrowserLineLogin') === "true" || window.localStorage.getItem( 'isLineBind' ) === "true" ) ) { // 有經過綁定流程或一般登入已綁定，才進行加入好友判斷
        if( LIFF_STOREregister && userNormalLoggedInStorage==="false" && ( addFriend === "true" || addFriendStatus )
        ) {
          // alert(`51. 3-1. 一般登入，LINE登入並完成綁定，已加入好友，將關閉視窗`)
          setShowLineBindForm(false);
        } else if( LIFF_STOREregister && ! userNormalLoggedInStorage && ( addFriend === "true" || addFriendStatus )
        ) {
          // alert(`52. 4-1-1. 4-2-2.LINE登入，已綁定，已加入好友，將關閉視窗`)
          setShowLineBindForm(false);
          // v5-1.20230906新增條件，一般登入，已加入官方帳號，仍先彈出綁定視窗做是否加入好友判斷(20230912改用API判斷，一般登入已綁定，不須登入LINE直接關閉)
        } else if( ! LIFF_STOREregister && userNormalLoggedInStorage==="true" && ( addFriend === "true" || addFriendStatus ) ) {
          // alert(`53. 5-1. 一般登入，已加入官方帳號，直接關閉`)
          setShowLineBindForm(false);
        // 5-2. (true null true null) 使用者已經過綁定流程，未加入官方帳號，彈出綁定視窗或加入好友視窗
        } else if( addFriend === "false" || ! addFriendStatus ) {
          // alert(`54. 5-2.未加入官方帳號，彈出綁定視窗或加入好友視窗`)
          setShowLineBindForm(true);
        } else if( window.localStorage.getItem('loginToken') ) {
          // alert(`55. Other`)
          setShowLineBindForm(true);
        } else {
            // alert(`55. Other`)
            setShowLineBindForm(false);
        }
      }
    }
  }

  // /*** 當使用者已通過綁定LINE流程，加入好友狀態改變時，判斷是否彈出加入好友視窗 ***/
  /*** 已檢查完好友，且已綁定登入，導向登入頁面 ***/
  useEffect(() => { 
    // alert(`1-2. addFriend: ${JSON.stringify(addFriend)}`)
    // alert(`1-3. addFriendStatus: ${JSON.stringify(addFriendStatus)}`)
    // alert(`1-4. loginToken: ${JSON.stringify(window.localStorage.getItem('loginToken'))}`)
    if( window.localStorage.getItem('loginToken') && addFriend !== undefined && addFriendStatus !== undefined ) {
      // addFriendPopup();
      window.location.href = lineBindRedir;
    }
  }, [addFriendStatus])

  /*** 每隔5秒檢查一次好友狀態 ***/
  let checkHasAddedFriendIntervalID = undefined;
  useEffect(() => { // 完成LIFF身分驗證流程後，且使用者已LINE登入或點選一般驗證才執行
    if( hasRunLineLoginAddFriendProcessAsync && ( window.localStorage.getItem('loginToken') || userNormalLoggedIn ) ) {
      checkHasAddedFriendIntervalID = setInterval(() => {
        checkHasAddedFriend();
      }, 5000)
    }
  }, [hasRunLineLoginAddFriendProcessAsync]);

  // /*** 先登入LIFF，再檢查好友，最後彈出加入好友視窗 ***/
  // const lineLoginAddFriendProcessAsync = async() => {
  //   try{
  //     // alert(`login()`)
  //     await login(); // 若為LINE瀏覽器，LIFF自動登入
  //     // alert(`popupLineLogin()`)
  //     await popupLineLogin(); // 20230802之後新增 彈出LINE綁定視窗
  //     // alert(`checkHasAddedFriend()`)
  //     await checkHasAddedFriend(); // 判斷是否為LINE瀏覽器，走API或LIFF好友偵測流程
  //     // alert(`addFriendPopup()`)
  //     await addFriendPopup(); // 20230913 彈出加入好友視窗
  //     // alert(`After addFriendPopup()`)
  //   } catch(error) {
  //     // alert(`56. lineLoginAddFriendProcessAsync error! ${error}`)
  //   }
  //   setHasRunLineLoginAddFriendProcessAsync(true);
  // }
  /*** 先登入LIFF，再檢查好友，最後彈出綁定視窗 ***/
  const lineLoginAddFriendProcessAsync = async() => {
    try{
      // alert(`login()`)
      await login(); // 若為LINE瀏覽器，LIFF自動登入
      // alert(`checkHasAddedFriend()`)
      await checkHasAddedFriend(); // 判斷是否為LINE瀏覽器，走API或LIFF好友偵測流程
      // alert(`popupLineLogin()`)
      await popupLineLogin(); // 20230802之後新增 彈出LINE綁定視窗
    } catch(error) {
      // alert(`56. lineLoginAddFriendProcessAsync error! ${error}`)
    }
    setHasRunLineLoginAddFriendProcessAsync(true);
  }

  /*** 清除使用者資料並重載頁面 ***/
  const clearDataReload = async(e) => {
    e?.preventDefault();
    await clearStorage(e); 
    window.location.reload();
  }
  /*** 頁面剛載入時執行，會較帶參數的useEffect延遲 ***/
  useEffect(() => {
    previousPageClearStorage();
    lineLoginAddFriendProcessAsync();
    isLineBrowser();
  }, []);

  return (
    showLineBindForm ? (
      <section className={popupStyle.modal}>
        <Modal.Title className={popupStyle.PopupBanner}>
          {/* <img src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"}></img> */}
          <img src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8e62509a-b227-4215-a18b-a4b0c7247f00/public"} onClick={(e)=>{clearDataReload(e)}}></img>
        </Modal.Title>
        <Modal.Body className={popupStyle.modalBody} >
          <LoginParent className={popupStyle.modalLoginParent} LoginToken={LoginToken}></LoginParent>
          {/* <button onClick={(e)=>{clearStorage(e); window.location.reload();}}>登出</button> */}
        </Modal.Body>
      </section>
    ) : (
      <>
        <SpinnerFullScreen></SpinnerFullScreen>
        {/* <button style={{width: '100%', position:'relative', top: '100px'}} onClick={(e)=>{clearStorage(e); window.location.reload();}}>登出</button> */}
      </>
    )
  )
}

export default Login
