import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import style from './styles/LineBindSuccess.module.css'
import LoadingSpinner from '../Spinner/Spinner'; // 引入loading 轉圈圈元件
import AuthContext from './components/AuthContext'
import AddFriend from './AddFriend';
const LineBindSuccess = (props) => {
    const {email, authorized, setAuth, auth, setEmail} = useContext(AuthContext); // LINE登入狀態
    const {setSheet}=props;
    const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
    const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
    const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
    const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
    const [userStatus, setUserStatus] = useState('login'); // 使用者要註冊或登入
    // 檢查使用者是否已輸入過手機，並設定預設值
    const storagePhoneNumber = !! window.localStorage.getItem('registerPhoneNumber') ? window.localStorage.getItem('registerPhoneNumber') : '';
    window.localStorage.setItem( 'registerPhoneNumber', storagePhoneNumber );     
    return (
        <>
            <Row className={style.lineBindSuccessRow}>
                <Col>
                    <div className={style.lineBindSuccessTitleDiv}>
                        <span className={style.lineBindSuccessTitle}>您的LINE帳號已完成綁定！</span>
                    </div>
                    { window.localStorage.getItem('addFriend') === 'false' || ! window.localStorage.getItem('addFriend') ? (
                        <>
                            {/* <AddFriend setSheet={setSheet}></AddFriend> */}
                            <div className={style.loginbuttosec}>
                                <button type="button" className={style.nextStepButton} onClick={(e) => { 
                                    e?.preventDefault();
                                    setRegxpError('');

                                    // 正式版
                                    // window.location.href = `${process.env.REACT_APP_LINE_BIND_REDIR}${window.localStorage.getItem('loginToken')}`

                                    // 測試用
                                    let loginTokenTest = "eyJhbGciOiJIUzI1NiJ9.JPDRavndQsJ6WSzXeRUvrmEL3OpidnU9HUdAJ-1MSMj7GxaP_vhWuO0agFL8oTDziI0lqr45SHWK2NxzyU58m6c_1O7jD5Lq76kCMxSgn-Z25uROB_UyqApFzfg1PBNZ6gPSzJscxu7n144bnPU-o-DSGtQqeS2KCAtETGtDQxw.jyvWCTHx1u7x_VxXRzdhPs85fM_gFpkqA5Lx6oErnrw";
                                    window.location.href = `${process.env.REACT_APP_LINE_BIND_REDIR}${loginTokenTest}`;

                                }}>OK</button>
                            </div>     
                        </>
                    ) : (
                        <div className={style.loginbuttosec}>
                            <button type="button" className={style.nextStepButton} onClick={(e) => { 
                                e?.preventDefault();
                                setRegxpError('');
                                
                                // 正式版
                                // window.location.href = `${process.env.REACT_APP_LINE_BIND_REDIR}${window.localStorage.getItem('loginToken')}`

                                // 測試用
                                let loginTokenTest = "eyJhbGciOiJIUzI1NiJ9.JPDRavndQsJ6WSzXeRUvrmEL3OpidnU9HUdAJ-1MSMj7GxaP_vhWuO0agFL8oTDziI0lqr45SHWK2NxzyU58m6c_1O7jD5Lq76kCMxSgn-Z25uROB_UyqApFzfg1PBNZ6gPSzJscxu7n144bnPU-o-DSGtQqeS2KCAtETGtDQxw.jyvWCTHx1u7x_VxXRzdhPs85fM_gFpkqA5Lx6oErnrw";
                                window.location.href = `${process.env.REACT_APP_LINE_BIND_REDIR}${loginTokenTest}`;

                                // setAuth({...auth,authorized:false});
                                // setEmail(null);
                                // setSheet('LoginIndex');
                                // localStorage.clear();
                            }}>OK</button>
                        </div>     
                    )} 
                    <Col>
                        <div className={style.RegxpErrorSec}>
                            <p className={style.RegxpError}>{RegxpError}</p>
                        </div>
                    </Col>          
                </Col>
            </Row>
        </>
    )
}

export default LineBindSuccess
