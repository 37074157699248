import React, { useState, useContext, createContext, useEffect, useRef } from 'react'
import AuthContextProvider from './Components/UAA/components/AuthContextProvider'
import { HashRouter, BrowserRouter, Routes, Route, Link } from 'react-router-dom' // React Route
import style from './styles/App.module.css';
import './styles/antStyle.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LoadingSpinner from './Components/Spinner/Spinner';
import popupStyle from './Components/styles/popup.module.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import * as Scroll from 'react-scroll';
import { Element, Events, animateScroll as scroll,  scroller } from 'react-scroll'
import NotFound from './NotFound';
import Login from './Components/UAA/Login';
import LoginEncode from './Components/UAA/LoginEncode' // LINE登入後控制重新導向頁面
import LineRegisterPhone from './Components/UAA/LineRegisterPhone';
import SpinnerFullScreen from './Components/Spinner/SpinnerFullScreen'

/***  antd ***/
import { Steps, Typography, Layout, Collapse, Button as AntButton } from 'antd';
import './styles/antStyle.css';


function App() {
  const [LoginToken, setLoginToken] = useState(''); // 設定login token

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<NotFound />}/>
          <Route path="/" element={<Login className={popupStyle.modalLoginParent} LoginToken={LoginToken}></Login>} />
          <Route path="/LineRegisterPhone" element={<LineRegisterPhone className={popupStyle.modalLoginParent} LoginToken={LoginToken}></LineRegisterPhone>} />
          <Route path="/LoginEncode" element={<LoginEncode />} />
          <Route path="/SpinnerFullScreen" element={<SpinnerFullScreen />} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App;
