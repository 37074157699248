import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import style from './styles/LoginIndex.module.css'
import divLine from './imgs/divLine.svg'
import CountryData from './coutryCode.json';
// import { sendRegisterVeriLink } from '../components/ModuleSendEmail'
import { sha256 } from 'js-sha256';
import axios from 'axios';
import LoadingSpinner from '../Spinner/Spinner'; // 引入loading 轉圈圈元件
import {phone} from 'phone'; // 手機驗證模組
import Timer from './components/Timer/Timer'; // SMS計時器
import liff from '@line/liff' // liff SDK


function LoginIndex(props) {

    const {setSheet}=props;
    const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)

    const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
    // console.log('smsveristatus',smsveristatus);

    /* 自動填入參照欄位 */
    const autoEmail = useRef(null);
    const autoPhone = useRef(null);

    const countryCodeRef = useRef(null); // 國碼參照欄位
    const phoneNumberRef = useRef(null); // 手機號碼欄位
    const smscode = useRef(null); // 簡訊驗證碼參照欄位
    const sendSMSText = useRef(null); // 發送驗證碼按鈕文字

    const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
    const [myform, setMyform] = useState({}); // 設定表單資料
    const [LoginWay, setLoginWay] = useState(false); // 設定進入電子郵件表單or 電話登入表單
    const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
    const [userStatus, setUserStatus] = useState('login'); // 使用者要註冊或登入
    const [returnLogin, setReturnLogin] = useState(false); // 設定手機是否已綁定過
    // const [smsveristatus, setsmsveristatus] = useState(false); // 檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(改成上層傳入)
    const [gotoSetPassword, setGotoSetPassword] = useState(false); // 點擊下一步觸發設定密碼表單，前往設定密碼
    const [passwordVeriStatus, setPasswordVeriStatus] = useState(false); // 檢查使用者輸入的密碼與確認密碼，以啟用下一步按鈕
    const [gotoSetEmail, setGotoSetEmail] = useState(false); // 點擊下一步觸發設定Email表單，前往設定註冊Email
    const [LineResponse, setLineResponse] = useState(false); // 設定line登入成功狀態

    /***** 註冊功能State *****/
    const [hasSendSMS, setHasSendSMS] = useState(false); // 檢查是否已發送驗證碼 
    const [verifySMSBtn, setVerifySMSBtn] = useState(true); // 驗證簡訊按鈕生效
    const [showRepassword, setShowRepassword] = useState(false); // 確認密碼是否顯示

    // 檢查使用者是否已輸入過手機，並設定預設值
    const storagePhoneNumber = !! window.localStorage.getItem('registerPhoneNumber') ? window.localStorage.getItem('registerPhoneNumber') : '';
    window.localStorage.setItem( 'registerPhoneNumber', storagePhoneNumber ); 

    const uaaApiURL = process.env.REACT_APP_UAA_APIURL // UAA API URL

    /******************** 表單註冊Function *********************/

    /* 清除Local Storage並登出LINE、FB */
    const clearStorage = (event) => {
        event.preventDefault();
        // 如果LINE有登入，執行LINE登出
        const isLoggedIn = liff.isLoggedIn();
        if(isLoggedIn){
        liff.logout();
        }
        // 如果FB物件存在，且為登入中，將FB登出
        if( !! window.FB ) {
        window.FB.getLoginStatus(function (response) {
            if(response.status==='connected') {
            window.FB.logout(function (response) {
                // console.log('handleFBLogout', response);
            });
            }
        });
        }
        localStorage.clear();
    }
    
    /* 時時記錄表單欄位值 */
    const changeFields = (event) => {
        event.preventDefault();
        setMyform({ ...myform, registerEmail: '' });
        const id = event.target.id;
        const val = event.target.value;
        // // console.log({ id, val });
        setMyform({ ...myform, [id]: val });
        // 檢驗手機是否已填寫
        if( id === 'phone' && val.length > 0 ) {
            window.localStorage.setItem( 'registerPhoneNumber', val );
        } else if( id === 'phone' && val.length === 0 ) {
            window.localStorage.setItem( 'registerPhoneNumber', '' );
        }
        setRegxpError('');
    }

    // 測試(直接通過簡訊驗證)
    // setsmsveristatus(true);
    // window.localStorage.setItem( 'phN', myform.countryCode + window.localStorage.getItem( 'registerPhoneNumber' ) );


    /* 設定國碼資料類型 */
    let coutryCodeArray = [];
    coutryCodeArray=Object.entries(CountryData.id_to_countrycode);
    
    /* 國碼加入預設值+886 */
    if( ! myform.countryCode){
        setMyform({ ...myform, countryCode: '+886' });
    }

    const clearMyformRegisterData = (event) => {
        event.preventDefault();
        setMyform({ ...myform, registerRepassword: '' });
        setMyform({ ...myform, registerEmail: '' });
    }

    /* 驗證手機格式 */
    const verifyPhoneFormat = ( phoneArgs, phoneArgsWithCountryCode ) => {
        let verifyResult = true;
        // console.log('verifyResult',verifyResult);
        // 驗證手機格式
        let phoneRegxp = /^[0-9]{1}[0-9]{4,19}$/;
        // 驗證無國碼手機號碼
        let phoneRegxpResult = phoneRegxp.test( phoneArgs ); 
        // console.log('phoneRegxpResult',phoneRegxpResult);

        // 利用phone module驗證含國碼手機號碼
        const veriPhoneObj = phone( phoneArgsWithCountryCode );
        // console.log('veriPhoneObj.isValid',veriPhoneObj.isValid);

        if( ! veriPhoneObj.isValid || ! phoneRegxpResult ) {
            setRegxpError('請填寫正確手機資訊！');
            verifyResult = false;
            // console.log('verifyResult',verifyResult);
            verifyResult = false;
        }  
        else {
            setRegxpError('');
        }
        // const arrayData = [];
        // arrayData.push( verifyResult );
        // arrayData.push( veriPhoneObj.phoneNumber );
        // // console.log( 'arrayData', arrayData );
        // return arrayData; // 0表示驗證結果布林值，1表示手機號碼淨化值
        return [verifyResult, veriPhoneObj.phoneNumber]; // 0表示驗證結果布林值，1表示手機號碼淨化值
    } /* END 驗證手機格式 */  

    /* 寄送SMS */
    const sendSMS = (event) => {
        event.preventDefault();
        setRegxpError(''); // 點選按鈕清空錯誤訊息 
        // console.log('sendSMS','sendSMS');

        // 重整頁面後/使用者更動欄位 之手機值
        let phone = "";
        let phoneWithCountryCode = "";
        try {
        if( !! window.localStorage.getItem( 'registerPhoneNumber' ) ) { // 重整頁面後以localStorage值做驗證及送簡訊
            phone = window.localStorage.getItem( 'registerPhoneNumber' );
            phoneWithCountryCode = myform.countryCode + window.localStorage.getItem( 'registerPhoneNumber' );
        } else { // 使用者更動欄位後以myform.phone值做驗證及送簡訊
            phone = myform.phone;
            phoneWithCountryCode = myform.countryCode + myform.phone;
        }
        } catch (error) {
            // console.log('error',error);
        }

        // 驗證手機格式
        // console.log('phone', phone);
        // console.log('phoneWithCountryCode', phoneWithCountryCode);
        // console.log( 'verifyPhoneFormat( phone, phoneWithCountryCode )', verifyPhoneFormat( phone, phoneWithCountryCode ) );
        let verifyResult = verifyPhoneFormat( phone, phoneWithCountryCode )[0]; // 0表示驗證結果布林值，1表示手機號碼淨化值
        // console.log('verifyResult',verifyResult);

        // 測試用
        // setVerifySMSBtn(false);
        // // console.log('sendSMS verifySMSBtn',verifySMSBtn);

        // console.log('phone',phone);
        let data = {
            phone: phoneWithCountryCode,
        } 
        // console.log('data',data);
        const loginToken = window.localStorage.getItem('loginToken');
        const BearerToken = 'Bearer ' + loginToken;

        // console.log('verifyResult',verifyResult);
        // // console.log('Timer', Timer);
        // 若手機驗證通過，POST送出手機資料，開始傳送簡訊驗證碼
        if(verifyResult) {

        // // console.log('開始傳送簡訊驗證碼');
        // // console.log('Timer', Timer);
        // TimerObj = new Timer;
        // TimerObj.startTimer(); // 開始計時
        // // console.log('TimerObj.state.time.s', TimerObj.state.time.s);

        // 
        fetch('https://' + uaaApiURL + '/api/uaa/message/phone_verification_code', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        })
        .then((r) => r.json())
        .then((result) => {
            // console.log('result', result);
            if ( result.statusCode === 200 ) {
                // console.log('result.statusCode', result.statusCode);
                setHasSendSMS(true);
                setVerifySMSBtn(false); // 等60秒才能再送簡訊
                setRegxpError('簡訊驗證碼已傳送！');
                alert('簡訊驗證碼傳送成功！'); 
            } else if ( result.statusCode === 400 ) {
                // console.log('result.statusCode', result.statusCode);
                //表單的資料輸入錯誤或資料沒有傳成功
                setRegxpError('請填寫正確的手機資料，再進行驗證！');
                alert('請填寫正確的手機資料，再進行驗證！'); 
            } else if ( result.statusCode === 409 ) {
                // console.log('result.statusCode', result.statusCode);
                //手機重複綁定
                setRegxpError('此手機號碼已完成綁定！！');
                alert('此手機號碼已完成綁定！'); 
                // setsmsveristatus(true);
                setReturnLogin(true); // 顯示返回登入
                setVerifySMSBtn(false); // 隱藏發送驗證碼
                window.localStorage.setItem('smsveristatus', 'true');
                // // console.log(window.localStorage.getItem('smsveristatus'));
            } else if ( result.statusCode === 429 ) {
                // console.log('result.statusCode', result.statusCode);
                setRegxpError('此手機號碼已完成綁定！');
                alert('此手機號碼已完成綁定！'); 
                // setsmsveristatus(true);
                setReturnLogin(true); // 顯示返回登入
                setVerifySMSBtn(false); // 隱藏發送驗證碼
                window.localStorage.setItem('smsveristatus', 'true');
            } else {
                // console.log('result.statusCode', result.statusCode);
                setRegxpError('目前無法寄送簡訊驗證碼，請稍候再試！');
                alert('目前無法寄送簡訊驗證碼，請稍候再試！'); 
            }
        })
        .catch((error) => {
            alert('伺服器錯誤！');
            // console.log(error);
        }); // END Fetch
        } else {
            setRegxpError('錯誤的手機格式！');
        }
    } /* END 寄送SMS */

    /* 監聽驗證碼，當點選驗證簡訊驗證碼，即開始驗證 */
    const submitSMS = (event) => {
        event.preventDefault();
        setRegxpError(''); // 點選按鈕清空錯誤訊息 
        if( smscode.current.value.length === 6 ) {
            verifySMS( smscode.current.value );
            setRegxpError('');
        } else {
            setRegxpError('請填入6碼簡訊驗證碼！');
            setsmsveristatus(false);
            window.localStorage.setItem('smsveristatus', 'false');
        }
        return;
    } /* END 監聽驗證碼 */

    /* 驗證SMS */
    const verifySMS = ( sms ) => {
        setIsLoading(true);
        // 重整頁面後/使用者更動欄位 之手機值
        let phoneNumber = "";
        if( !! window.localStorage.getItem( 'registerPhoneNumber' ) ) {
            phoneNumber = myform.countryCode + window.localStorage.getItem( 'registerPhoneNumber' );
        } else {
            phoneNumber = myform.countryCode + myform.phone;
        }
        // console.log('phoneNumber',phoneNumber);
        let data = {
            'phone': phoneNumber,
            'code': sms
        }
        // console.log('data',data);
        const loginToken = window.localStorage.getItem('loginToken');
        const BearerToken = 'Bearer ' + loginToken;
        // // console.log('data', data);
        fetch('https://' + uaaApiURL + '/api/uaa/message/phone_verify', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((r) => r.json())
        .then((result) => {
            if ( result.statusCode === 200 ) {
                window.localStorage.setItem('smsveristatus', 'true');
                setIsLoading(false);
                setsmsveristatus(true);
                setRegxpError('簡訊驗證成功！');
                // 不含國碼
                // console.log('phoneNumberRef.current',phoneNumberRef.current);
                if( !! phoneNumberRef.current ) {
                    window.localStorage.setItem( 'verifiedPhone', phoneNumberRef.current.value );
                }
                // 含國碼
                // console.log('phoneNumber',phoneNumber);
                if( !! phoneNumber ) {
                    let veriPhoneMod = phone( phoneNumber );
                    // console.log('veriPhoneMod',veriPhoneMod);
                    let santiPhone = veriPhoneMod.phoneNumber;
                    // console.log('santiPhone',santiPhone);
                    window.localStorage.setItem( 'phN', santiPhone );
                }
                // alert('簡訊驗證成功！'); 
                // 成功驗證簡訊，則更新手機資料
                // fetch('https://' + uaaApiURL + '/api/uaa/phone_verify', {
                //   method: 'PUT',
                //   body: JSON.stringify(data),
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': BearerToken
                //   },
                // })
                // .then((r) => r.json())
                // .then((result) => {
                //   if ( result.status === 200 ) {
                //     setIsLoading(false);
                //     setsmsveristatus(true);
                //     window.localStorage.setItem('smsveristatus', 'true');
                //     setRegxpError('簡訊驗證成功！'); 
                //     alert('簡訊驗證成功！'); 
                //     window.location.reload();
                //   } else if ( result.status === 400 ) {
                //     setIsLoading(false);
                //     setsmsveristatus(false);
                //     window.localStorage.setItem('smsveristatus', 'false');
                //     setRegxpError('錯誤的簡訊驗證碼！');
                //     alert('錯誤的簡訊驗證碼！'); 
                //   } else if ( result.status === 403 ) {
                //     setIsLoading(false);
                //     setsmsveristatus(false);
                //     window.localStorage.setItem('smsveristatus', 'false');
                //     setRegxpError('此手機號碼無法被註冊！');
                //     alert('此手機號碼無法被註冊！'); 
                //   }else if ( result.status === 409 ) {
                //     setIsLoading(false);
                //     setsmsveristatus(true);
                //     window.localStorage.setItem('smsveristatus', 'true');
                //     setRegxpError('此手機號碼已完成綁定！');
                //     alert('此手機號碼已完成綁定！'); 
                //   }
                // })
                // .catch((error) => {
                //   // // console.log('error',error);
                //   setIsLoading(false);
                //   alert('伺服器錯誤，請稍後再試！')
                // });
            } else if ( result.statusCode === 400 ) {
                setIsLoading(false);
                setsmsveristatus(false);
                window.localStorage.setItem('smsveristatus', 'false');
                setRegxpError('錯誤的簡訊驗證碼！');
                // alert('錯誤的簡訊驗證碼！'); 
            } else if ( result.statusCode === 409 || result.statusCode === 429 ) {
                setIsLoading(false);
                setsmsveristatus(false);
                window.localStorage.setItem('smsveristatus', 'true');
                setRegxpError('此手機號碼已完成綁定！');
                // alert('此手機號碼已完成綁定！'); 
            } else {
                setIsLoading(false);
                setsmsveristatus(false);
                window.localStorage.setItem('smsveristatus', 'false');
                setRegxpError('手機號碼或簡訊驗證碼輸入錯誤！');
                // alert('手機號碼或簡訊驗證碼輸入錯誤！'); 
            }
        })
        .catch((error) => {
            // console.log(error);
            setIsLoading(false);
            setRegxpError('伺服器錯誤，請稍候再試！');
            alert('伺服器錯誤，請稍候再試！');
        });
    }  
    /* 60秒後將簡訊驗證按鈕打開 */
    useEffect(() => {
        let timer = "";
        const executeTimeout = async() => {
        if( ! verifySMSBtn ) {
            timer = setTimeout(() => {
            setVerifySMSBtn(true);
            // // console.log('SetTimeout verifySMSBtn',verifySMSBtn);
            }, 60000);
        }
        }
        executeTimeout();
        return () => clearTimeout(timer);
    });

    // /* 儲存待傳出的手機號碼 */
    // const setPhone = (event) => {
    //     event.preventDefault();
    //     if( smsveristatus ) {
    //         if( !! countryCodeRef.current &&  !! phoneNumberRef.current ) {
    //             window.localStorage.setItem( 'phN', countryCodeRef.current.value + phoneNumberRef.current.value );
    //         }
    //     }
    // }

    // const whenRegister = (event) => {
    //     event.preventDefault();
    //     setRegxpError(''); // 點選按鈕清空錯誤訊息 
    //     // // console.log('我是發request');
    //     let registerPhone = myform.countryCode + myform.phone;
    //     let registerEmail = myform.email;
    //     let registerPassword = myform.registerPassword;
    //     let registerRepassword = myform.registerRepassword;
    //     let phoneVerifyToken = localStorage.getItem('phoneVerifyToken');
    //     let data = {
    //     phone: registerPhone,
    //     email: registerEmail,
    //     password: registerPassword,
    //     checkPassword: registerRepassword,
    //     phoneVerifyToken: phoneVerifyToken,
    //     lineToken: ""
    //     }
    //     fetch('https://' + uaaApiURL + '/api/uaa/register', {
    //     method: 'POST',
    //     body: JSON.stringify(myform),
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     })
    //     .then((r) => r.json())
    //     .then((result) => {
    //     // // console.log('result', result);
    //     if ( result.status === 201 ) {
    //         alert('註冊成功！');
    //     } else if ( result.status === 400 ) {
    //         alert('錯誤的Email/手機格式，或密碼與確認密碼不一致！');
    //     } else if ( result.status === 403 ) {
    //         alert('瀏覽器的存取權限受到限制，請稍後再試！');
    //     } else if ( result.status === 429 ) {
    //         alert('您的Email或手機號碼已被註冊！');
    //     } else {
    //         alert('伺服器錯誤！');
    //     }
    //     })
    //     .catch((error) => {
    //     alert('伺服器錯誤！');
    //     // // console.log(error);
    //     });  
    // }
    /******************** END 表單註冊Function *********************/

    return (
        <>
        <Row>
            <Col>
                <Form className={style.form}>
                    <Col className="mb-3">
                        <div className={style.KidProLoginTitleBox}>
                            <span className={style.KidProLoginTitle}>註冊</span>
                            <div className={style.KidProLoginMarkBox}>
                                <img className={style.KidProLoginMark} src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/5e621921-e54f-4e57-8d2a-8d5ddddd8c00/public"}></img>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Form.Group className="mb-4">
                            <Form.Label className={style.LoginLabel}>手機註冊</Form.Label>
                            {/* <div className={style.PhoneSec}>
                                { !! smsveristatus ? 
                                (
                                    <>
                                        <select className={style.countryCode} id="countryCode" onChange={changeFields} value={myform.countryCode} disabled required>
                                            {coutryCodeArray.map((v,i)=>{
                                            return(
                                                <>
                                                    <option href="#/action-1" key={i+1}>{v[1]}</option>
                                                </>    
                                                )
                                            })}
                                        </select>
                                        <Form.Control className={style.form_inputPhone} type="tel" id="phone" name='phone' pattern="[0-9|\-|\s]{5,20}" onChange={changeFields} value={myform.phone} disabled required/>
                                    </>
                                ) : 
                                (
                                    <>
                                        <select className={style.countryCode} id="countryCode" onChange={changeFields} value={myform.countryCode} required>
                                        {coutryCodeArray.map((v,i)=>{
                                            return(
                                                <>
                                                    <option href="#/action-1" key={i+1}>{v[1]}</option>
                                                </>    
                                            )
                                            })}
                                        </select>
                                        <Form.Control className={style.form_inputPhone} type="tel" id="phone" name='phone' pattern="[0-9|\-|\s]{5,20}" onChange={changeFields} value={myform.phone} required/>                    
                                    </>
                                )} 
                            </div>   */}
                            <div className={style.PhoneSec}>
                                {   !! window.localStorage.getItem( 'verifiedPhone' ) && !! smsveristatus ? 
                                    ( 
                                        <>
                                        <select className={style.countryCode} id="countryCode" onChange={changeFields} ref={countryCodeRef} value={myform.countryCode} required disabled>
                                            {coutryCodeArray.map((v,i)=>{
                                                return(
                                                    <>
                                                        <option href="#/action-1" key={i+1}>{v[1]}</option>
                                                    </>    
                                                )
                                            })}
                                        </select>
                                        <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="^[1-9]{1}[0-9|\-|\s]{4,19}$" ref={phoneNumberRef} onChange={changeFields} value={window.localStorage.getItem('verifiedPhone')} placeholder='範例：910123456' required disabled/>
                                        {/* <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="^[1-9]{1}[0-9|\-|\s]{4,19}$" className={style.form_inputPhone} ref={phoneNumberRef} onChange={changeFields} value={window.localStorage.getItem('verifiedPhone')} placeholder='範例：910123456' required disabled/> */}
                                        </>
                                    )
                                    : !! window.localStorage.getItem( 'registerPhoneNumber' ) ?
                                    ( 
                                        <>
                                        <select className={style.countryCode} id="countryCode" onChange={changeFields} ref={countryCodeRef} value={myform.countryCode} required>
                                            {coutryCodeArray.map((v,i)=>{
                                                return(
                                                    <>
                                                        <option href="#/action-1" key={i+1}>{v[1]}</option>
                                                    </>    
                                                )
                                            })}
                                        </select>
                                        <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="^[1-9]{1}[0-9|\-|\s]{4,19}$" ref={phoneNumberRef} onChange={changeFields} value={window.localStorage.getItem('registerPhoneNumber')} placeholder='範例：910123456' required/> 
                                        {/* <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="^[1-9]{1}[0-9|\-|\s]{4,19}$" className={style.form_inputPhone} ref={phoneNumberRef} onChange={changeFields} value={window.localStorage.getItem('registerPhoneNumber')} placeholder='範例：910123456' required/>  */}
                                        </>
                                    )
                                    :
                                    ( 
                                        <>
                                        <select className={style.countryCode} id="countryCode" onChange={changeFields} ref={countryCodeRef} value={myform.countryCode} required>
                                            {coutryCodeArray.map((v,i)=>{
                                            return(
                                            <>
                                                <option href="#/action-1" key={i+1}>{v[1]}</option>
                                            </>    
                                            )
                                            })}
                                        </select>
                                        <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="^[1-9]{1}[0-9|\-|\s]{4,19}$"  ref={phoneNumberRef} onChange={changeFields} value={myform.phone} placeholder='範例：910123456' required/> 
                                        {/* <input className={style.inputinfiled} type="tel" id="phone" name='phone' pattern="^[1-9]{1}[0-9|\-|\s]{4,19}$"  className={style.form_inputPhone} ref={phoneNumberRef} onChange={changeFields} value={myform.phone} placeholder='範例：910123456' required/>  */}
                                        </>
                                    )
                                }
                            </div>                            
                            { !! smsveristatus ? 
                                (
                                <div className={style.form_reverifySec}>
                                    <div className={style.form_buttonPhoneDiv}>
                                        <button type="button" className={style.form_reverifyButton} onClick={()=>{setsmsveristatus(false);setHasSendSMS(false);setVerifySMSBtn(true);}}>重新驗證</button>
                                    </div>                       
                                </div>
                                ) : (
                                <></>
                            )}              
                        </Form.Group>
                        { /* 動態顯示簡訊送出及簡訊驗證區塊 */ }
                        {/* <div ref={showSmsDivRef} className={style.form_lableCardSMS}> */}
                        <Col className="mb-4">
                        {/* <div className={style.form_lableCardSMS}> */}
                        {/* 
                            * 使用者已綁定手機，不顯示發送驗證碼與驗證驗證碼區塊；
                            * 未綁定，則顯示發送驗證碼按鈕；
                            * 若已發送驗證碼，則顯示輸入驗證碼欄位及驗證簡訊欄位
                            */}
                        {/* { !! window.localStorage.getItem('phoneNumber') && !! formPhone ? ( */}
                        { !! smsveristatus ? 
                        // { false ? 
                            (
                                <></>
                            )
                            : ! smsveristatus && ! hasSendSMS ?
                            // : false ?
                            (  
                                <>
                                    <div className={style.form_smsPadding}></div>
                                    <div className={style.form_smsSec}>
                                        <div className={style.form_buttonPhoneDiv}>
                                            { verifySMSBtn ? (
                                            <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS}>發送驗證碼</button>
                                            ) : (
                                            <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS} disabled>發送驗證碼</button>
                                            ) }
                                            {/* <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS}>發送驗證碼</button> */}
                                        </div>                       
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div className={style.form_smsPadding}></div>
                                    <div className={style.form_smsSec}>
                                        <div className={style.form_buttonPhoneDiv}>
                                            { verifySMSBtn ? (
                                            <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS}>發送驗證碼</button>
                                            ) : (
                                            <button type="button" ref={sendSMSText} className={style.form_buttonPhone} onClick={sendSMS} disabled><Timer></Timer></button>
                                            ) }
                                        </div>
                                        <div id="veifySMSDiv"  className={style.form_verifySMSDiv}>
                                            <div className={style.form_inputPhoneSec}>
                                                <label className={style.form_labelPhone} >簡訊驗證碼（6碼）</label>
                                                <input type="text" id="sms" name="sms" maxLength="6" ref={smscode} className={style.form_inputSMS} value={myform.sms} />               
                                            </div>
                                            <div className={style.form_buttonPhoneDiv}>
                                                {isLoading?<><LoadingSpinner></LoadingSpinner></>:<><button type="button" className={`${style.form_buttonPhone} ${style.form_buttonPhoneSubmitSMS}`} onClick={submitSMS}>開始驗證</button></>}
                                            </div>
                                        </div>                           
                                    </div>
                                </>
                            )
                        }
                        </Col>
                    </Col>        
                    <Col>
                        <div className={style.RegxpErrorSec}>
                            <p className={style.RegxpError}>{RegxpError}</p>
                        </div>
                    </Col>          
                    <Col className="mb-4">
                    { returnLogin ? (
                        <>
                            <div className={style.directSec}>
                                <button type="button" className={style.nextStepButton} onClick={ (event) => { 
                                    setSheet('LoginIndex');
                                    // setUserStatus( 'login' ); 
                                    setLoginWay( false );
                                    setsmsveristatus(false);
                                    setHasSendSMS(false);
                                    // setGotoSetPassword( false );
                                    setShowRepassword(false);
                                    setPasswordVeriStatus( false );
                                    // setGotoSetEmail( false );  
                                    setRegxpError('');
                                    clearStorage(event) ;
                                }}>前往登入</button>
                            </div>
                        </>
                    ) : smsveristatus ? (
                        <>
                            <div className={style.loginbuttosec}>
                                <button type="button" className={style.nextStepButton} onClick={ (event) => { 
                                    // setGotoSetPassword(true);
                                    setSheet('registerPassword');
                                    // setPhone(event);
                                    setShowRepassword(false);
                                    setRegxpError('');
                                }}>下一步</button>
                            </div>     
                        </>       
                    ) : (
                        <>
                            <div className={style.loginbuttosec}>
                                <button type="button" className={style.nextStepButton} disabled>下一步</button>
                            </div>
                        </>
                    ) }
                    </Col>
                    {/* <Col className="mb-1">
                        <div className={style.directSec}>
                            <p className={style.regSec} onClick={ () => {
                                setSheet('register');
                                // setUserStatus( 'login' ); 
                                setLoginWay( false );
                                setsmsveristatus(false);
                                setHasSendSMS(false);
                                // setGotoSetPassword( false );
                                setShowRepassword(false);
                                setPasswordVeriStatus( false );
                                // setGotoSetEmail( false );  
                                setRegxpError(''); 
                            }}><span>以LINE帳號註冊</span></p>
                        </div>
                    </Col>           */}
                </Form>
            </Col>
        </Row>
        </>
    )
}

export default LoginIndex;