/*
 * 20230802 改為只能用LINE註冊
*/
import React, { useState, useContext,useEffect } from 'react'
import style from './styles/LoginIndex.module.css'
import RegisterStyle from './styles/Register.module.css'
import { Container,Row,Col } from 'react-bootstrap'
import popupStyle from './styles/popup.module.css'
import Modal from 'react-bootstrap/Modal'
import lineIcon from './imgs/LINE.png'
import bannerForPhone from './imgs/RegisterPhoneBanner.png'
import liff from '@line/liff' // liff SDK
import PopupBanner from './imgs/PopupBanner.png'
import AuthContext from './components/AuthContext'

function Register(props) {

  const { Sheet, setSheet, setLineRegister}= props;
  const { authorized, setAuth, auth, setEmail, email } = useContext(AuthContext);
  const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
  const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)

  const [myform, setMyform] = useState({}); // 設定表單資料
  const [isLineBrowserStatus, setIsLineBrowserStatus] = useState(false); // 設定是否為LINE瀏覽器

  const userNormalLoggedInStorage = window.localStorage.getItem('userNormalLoggedIn'); // 使用者是否先用一般登入
  const loginToken = window.localStorage.getItem('loginToken') ?? ""
  const lineBindRedir = process.env.REACT_APP_LINE_BIND_REDIR + loginToken;
  const LIFF_STOREregister = window.localStorage.getItem('lineRegister'); // 取得LINE註冊狀態
  // useEffect(() => { 
  //   // console.log('Sheet: ', Sheet, '!! loginToken', !! loginToken, 'LIFF_STOREregister', LIFF_STOREregister, 'userNormalLoggedInStorage: ', userNormalLoggedInStorage, 'email: ',  email );
  //   // console.log(! loginToken && LIFF_STOREregister==="true" && userNormalLoggedInStorage==="false" && email===null);
  // }, [Sheet, loginToken, email, userNormalLoggedInStorage, LIFF_STOREregister]);

  const clearMyformRegisterData = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerRepassword: '' });
    setMyform({ ...myform, registerEmail: '' });
  }  

  //設定Modal 顯示與否
  const [show, setShow] = useState(false);
  //設定line登入成功狀態
  const [LineResponse, setLineResponse] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
  
    return (
      <>
          <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
                <Col className={popupStyle.modal}>
                <div className={popupStyle.modalClose} onClick={(event)=>{handleClose();clearStorage(event);}}>x</div>
                  <div className={popupStyle.modalHeader} >
                  <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                  <div className={popupStyle.PopupBanner}>
                    <img src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8e62509a-b227-4215-a18b-a4b0c7247f00/public"></img>
                  </div>
                 </div>
                <Modal.Body className={popupStyle.modalBody} >
             
                </Modal.Body>
              </Col>
          </Modal>
        </>
      </>
    );
  }

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if(isLoggedIn){
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    localStorage.clear();
  }

  const lineid = process.env.REACT_APP_LINE_APP_ID 
  const BACKENDURL = process.env.REACT_APP_BACKENDURL;
  const linelogin = async() => {
    const init = await liff.init({
        liffId: lineid, // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login processFBStatus
       
    })
    .catch((err) => {
        // Todo: retry init.
        // // console.log('err',err);
    });

  }
  /* 進行LINE登入，導向LoinEncode頁面 */
  const LineAuth = () => {
      const isLoggedIn = liff.isLoggedIn();

      /*** 20230802 改強迫LINE登入後 ***/
      // (改Path這裡必須設定)設定使用者取得LINE ACCESS TOKEN後導向位置(無論使用者有無綁定LINE)，只要有新增新的path，就要在這裡做更動
      let userNormalLoggedInUrlPara = "";
      if( userNormalLoggedInStorage === 'true' ) { // 20230802 強迫使用者用LINE登入：使用者先一般登入，再點選LINE註冊/登入，使用者取得LINE ACCESS TOKEN後導向位置
        window.localStorage.setItem( 'userNormalLoggedIn', false ); 
        userNormalLoggedInUrlPara = "&userNormalLoggedIn=false";
      }
      const wpLoginEmailUrlPara = window.localStorage.getItem('wpLoginEmail') ? `&email=${window.localStorage.getItem('wpLoginEmail')}` : "";
      if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
        liff.login({ redirectUri: "https://" + BACKENDURL +"/LoginEncode?page=grantmembership&action=register" + userNormalLoggedInUrlPara + wpLoginEmailUrlPara });
        setLineResponse(isLoggedIn);
      } else if ( window.location.pathname.toLowerCase().indexOf('groupbuy') > -1 && window.location.pathname.toLowerCase().indexOf('groupbuycheckout') === -1 && window.location.pathname.toLowerCase().indexOf('groupbuylistteams') === -1 ) {
        // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
        liff.login({ redirectUri: "https://" + BACKENDURL +"/LoginEncode?page=groupbuy&action=register" + userNormalLoggedInUrlPara + wpLoginEmailUrlPara });
        setLineResponse(isLoggedIn);
      } else if ( window.location.pathname.toLowerCase().indexOf('groupbuycheckout') > -1 ) {
        // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
        liff.login({ redirectUri: "https://" + BACKENDURL +"/LoginEncode?page=groupbuycheckout&action=register" + userNormalLoggedInUrlPara + wpLoginEmailUrlPara });
        setLineResponse(isLoggedIn);
      } else if ( window.location.pathname.toLowerCase().indexOf('groupbuylistteams') > -1 ) {
        // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
        liff.login({ redirectUri: "https://" + BACKENDURL +"/LoginEncode?page=groupbuylistteams&action=register" + userNormalLoggedInUrlPara + wpLoginEmailUrlPara });
        setLineResponse(isLoggedIn);
      } else {
        liff.login({ redirectUri: "https://" + BACKENDURL +"/LoginEncode?page=homepage&action=register" + userNormalLoggedInUrlPara + wpLoginEmailUrlPara });
        setLineResponse(isLoggedIn);
      }

      /*** 20230802 改強迫LINE登入前 ***/
      // // if(!isLoggedIn){
      // liff.login({ redirectUri: "https://"+ BACKENDURL +"/LoginEncode?page=register" });
      // // liff.login();
      // setLineResponse(isLoggedIn);
      // // }
  }

  /* 檢查使用者瀏覽器 */
  const isLineBrowser = async() => {
    const isLineBrowserBool = /Line/i.test(navigator.userAgent);
    setIsLineBrowserStatus(isLineBrowserBool);
    return isLineBrowserBool;
  }
  
  useEffect(() => {
    linelogin();
    isLineBrowser();
  }, []);

  return (
    <>
      <Popup></Popup>
      <div className='p-4'>
      {/* <div style={{width:'100%'}}>
      // 要改POPUP HEADER層
         <img style={{width:'100%'}} src={bannerForPhone}></img>
     </div> */}
      {/* <Col className="mb-2">
            <div className={RegisterStyle.KidProLoginTitleBox}>
              <span className={RegisterStyle.KidProLoginTitle}>註冊 新會員</span>
            </div>
      </Col> */}
      {/* <Col>
        <p className={RegisterStyle.subTitle}>透過LINE綁定／註冊</p>
      </Col> */}
      <Col>
        <p className={RegisterStyle.subTitle}>LINE註冊/綁定</p>
        <p className={RegisterStyle.subTitle2}>( 您的LINE帳號將作為後續活動通知使用 )</p>
      </Col>
      <Col>
       <div className={RegisterStyle.LINE} onClick={LineAuth}>
          <img className={RegisterStyle.LINEimg} src={lineIcon}></img>
       </div>
      </Col>
      <Col>
        <div className={RegisterStyle.directSec}>
          {/* <div  onClick={() => { setSheet('registerPhone'); window.localStorage.setItem('lineRegister', ''); }}>
              <div className={RegisterStyle.directtoNoline}>
                <div>沒有LINE帳號</div>
                <div>(手機註冊)</div>
              </div>
          </div> */}
          {/* <div  onClick={()=>{ setSheet('LoginIndex'); }}>
              <span style={{display:"none"}} className={RegisterStyle.directtoLogin}>手機／Email登入</span>
              <div className={RegisterStyle.directtoNoline}>
                <div>手機／</div>
                <div>Email登入</div>
              </div>
          </div> */}
          { ! isLineBrowserStatus ? (
            <div onClick={()=>{ window.localStorage.clear(); setEmail(null); setSheet('register'); setTimeout(()=>{setSheet('LoginIndex');}, 1000) }}>
              <span className={RegisterStyle.directtoLogin}>返回登入表單</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Col>
      </div>
    </>
    
  )
}

export default Register