import React, { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import style from './styles/LineRegisterSuccess.module.css'
import LoadingSpinner from '../Spinner/Spinner'; // 引入loading 轉圈圈元件
import AuthContext from './components/AuthContext'
import AddFriend from './AddFriend';
const LineRegisterSuccess = (props) => {
    const {email, authorized, setAuth, auth, setEmail} = useContext(AuthContext); // LINE登入狀態
    const {setSheet}=props;
    const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
    const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
    const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
    const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
    const [userStatus, setUserStatus] = useState('login'); // 使用者要註冊或登入
    // 檢查使用者是否已輸入過手機，並設定預設值
    const storagePhoneNumber = !! window.localStorage.getItem('registerPhoneNumber') ? window.localStorage.getItem('registerPhoneNumber') : '';
    window.localStorage.setItem( 'registerPhoneNumber', storagePhoneNumber );     
    return (
        <>
            <Row className={style.lineRegisterSuccessRow}>
                <Col>
                    <div className={style.lineRegisterSuccessTitleDiv}>
                        <span className={style.lineRegisterSuccessTitle}>LINE註冊失敗，</span><span className={style.lineRegisterSuccessTitle}>請重新註冊！</span>
                    </div>
                    <div className={style.loginbuttosec}>
                        <button type="button" className={style.nextStepButton} onClick={(e) => { 
                            e?.preventDefault();
                            setRegxpError('');
                            setAuth({...auth,authorized:false});
                            setEmail(null);
                            // setSheet('LoginIndex');
                            localStorage.clear();
                            window.location.href = `/`;
                        }}>OK</button>
                    </div>     
                    <Col>
                        <div className={style.RegxpErrorSec}>
                            <p className={style.RegxpError}>{RegxpError}</p>
                        </div>
                    </Col>          
                </Col>
            </Row>
        </>
    )
}

export default LineRegisterSuccess
