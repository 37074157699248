import React, { useState, useContext, useEffect, useRef } from 'react'
import style from './styles/AccountExistNotify.module.css';
import { Container,Row,Col } from 'react-bootstrap';
import popupStyle from '../styles/popup.module.css';
import Modal from 'react-bootstrap/Modal'
import AuthContext from './components/AuthContext';
//引入loading 轉圈圈元件
import LoadingSpinner from '../Spinner/Spinner';
import liff from '@line/liff';
import LoginParent from './LoginParent';

function AccountExistNotify(props) {
  const {setSheet,setClearData,LoginToken} =props;
  const { authorized, setAuth, auth,setEmail,email } = useContext(AuthContext);

  //設定Modal 顯示與否
  const [show, setShow] = useState(false);
  // 設定loading 轉圈圈顯示與否
  const [isLoading, setIsLoading] = useState(false);
  // 顯示錯誤訊息
  const [RegxpError, setRegxpError] = useState('');
  // 設定登入資料
  const [myform, setMyform] = useState({});
  // 設定改訂email 可輸入
  // const [EmailStatus, setEmailStatus] = useState(false);

  const loginToken = window.localStorage.getItem('loginToken') ?? ""
  const lineBindRedir = process.env.REACT_APP_LINE_BIND_REDIR + loginToken;

   // 自動填入參照欄位
   const autoEmail = useRef(null);

   /* 時時記錄表單欄位值 */
   const changeFields = (event) => {
    event.preventDefault();
    setMyform({ ...myform, registerEmail: '' });
    const id = event.target.id;
    const val = event.target.value;
    // // // console.log({ id, val });
    setMyform({ ...myform, [id]: val });
    setRegxpError('');
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const {setSheet}=props;
    
    return (
      <>
          <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
                <Col className={popupStyle.modal}>
                <Modal.Header className={popupStyle.modalHeader} closeButton>
                  <Modal.Title className={popupStyle.modalTitle} ></Modal.Title>
                </Modal.Header>
                <Modal.Body className={popupStyle.modalBody} >
                  <LoginParent className={popupStyle.modalLoginParent} ></LoginParent>
                </Modal.Body>
              </Col>
          </Modal>
        </>
      </>
    );
  }
   

  /* 使用者點選登入 */
  const whenSubmit = ( event ) => {
    event.preventDefault();
    // 刪除前段錯誤訊息
    setRegxpError( '' );
    setIsLoading( true );
    window.localStorage.removeItem('LIFF_STOREregister'); 
    window.localStorage.removeItem('AccountExistNotify');  // 避免一直彈出此視窗
    let lineRegisterLoginToken = window.localStorage.getItem( 'lineRegisterLoginToken' );
    window.localStorage.setItem( 'loginToken', lineRegisterLoginToken );
    // window.location.assign( "/" ); // 登入成功後導向官網登入再返回CheckActivity頁面
    // 設定使用者先以一般登入後，再檢查LINE是否綁定後的導向位置
    if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
      window.location.assign( "/GrantMembership" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
    } else if ( window.location.pathname.toLowerCase().indexOf('groupbuy') > -1 && window.location.pathname.toLowerCase().indexOf('groupbuycheckout') === -1 && window.location.pathname.toLowerCase().indexOf('groupbuylistteams') === -1 ) {
      // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
      window.location.assign( "/GroupBuy" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
    } else if ( window.location.pathname.toLowerCase().indexOf('groupbuycheckout') > -1 ) {
      // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
      window.location.assign( "/GroupBuyCheckout" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
    } else if ( window.location.pathname.toLowerCase().indexOf('groupbuylistteams') > -1 ) {
      // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
      window.location.assign( "/GroupBuyListTeams" ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
    } else {
      window.location.assign( lineBindRedir ); // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
    }
  } /* END Function 使用者點選登入 */


  /* 清空用戶資料，返回最初登入頁面 */
  let LIFF_STOREregister =window.localStorage.getItem('LIFF_STOREregister'); 
  const ReturntoRegister =(event)=>{
    event.preventDefault();
    // if( LIFF_STOREregister ) {
    //   window.localStorage.removeItem('LIFF_STOREregister'); 
    // }
    liff.logout();
    // 如果FB物件存在，且為登入中，將FB登出
    if( !! window.FB ) {
      window.FB.getLoginStatus(function (response) {
        if(response.status==='connected') {
          window.FB.logout(function (response) {
            // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    setSheet('LoginIndex');
    setEmail(null);
    localStorage.clear();
  }

  // 屏蔽Email用户名的一部分字符
  function maskEmail(email) {
    const parts = email.split('@');
    const username = parts[0];
    const domain = parts[1];
    const maskedUsername = username.substring(0, 4) + '*'.repeat(username.length - 4) + '@' + domain;
    return maskedUsername;
  }

  return (
    <>
     <Col>
          <div>
            <div className={style.title}>您的LINE帳號已綁定 <div className={style.title} style={{color: "black"}}>{maskEmail(window.localStorage.getItem('wpEmail'))}</div></div>
          </div>
        </Col>
        <Col>
          <div className={style.RegxpErrorSec}>
            <p className={style.RegxpError}>{RegxpError}</p>
          </div>

          {/* <div className={style.confirmTitle}>
            <p>是否登入?</p>
          </div> */}
          <div className={style.confirmSec} >
            <div className={style.login_btnSec}  >
              {isLoading===true?(< LoadingSpinner></LoadingSpinner>):(<button onClick={whenSubmit} className={style.login_btn}>登入</button>)}
            </div>
            {/* <div className={style.Return} >
              <button className={style.ReturnButton} onClick={ReturntoRegister}>返回</button>
            </div> */}
          </div>
        </Col>
     
       
   

    
    </>
  
  )
}

export default AccountExistNotify