import React from 'react'
import { useState, useEffect, useRef } from 'react'

/* Popup相關 */
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from 'react-bootstrap/Button'
import { Col, Container, Form, Row } from "react-bootstrap";
import phoneUnlinkPopupStyle from './styles/phoneUnlinkPopup.module.css'
import {phone} from 'phone';
import Timer from './components/Timer/Timer';
import LoadingSpinner from '../Spinner/Spinner';
import PopupBanner from './imgs/PopupBanner.png';
import CountryData from './coutryCode.json';
import nodeRSA from "node-rsa"; // RSA加密元件
import LoginParent from './LoginParent';
/* END Popup相關 */

function FormPhoneUnlink() {
    const [myform, setMyform] = useState({});
    const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
    const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
    const [isLoadingSMS, setIsLoadingSMS] = useState(false);
    const [isLoadingPhoneUnlink, setIsLoadingPhoneUnlink] = useState(false);
    const ref = useRef(null);
    const smscode = useRef(null);
    const phoneNumberRef = useRef(null);
    const sendSMSText = useRef(null);
    const [formdata, updateFormdata] = useState();
    const [smsVeriStatus, setSmsVeriStatus] = useState(false); // 檢查使用者是否有通過簡訊驗證
    const [hasSendSMS, setHasSendSMS] = useState(false); // 檢查是否已發送驗證碼
    const [verifySMSBtn, setVerifySMSBtn] = useState(true); // 驗證簡訊按鈕生效
    const uaaApiURL = process.env.REACT_APP_UAA_APIURL // UAA API URL

    // 自動填入參照欄位
    const autoEmail = useRef(null);
    const autoPhone = useRef(null);

    /* 設定國碼資料類型 */
    let coutryCodeArray = [];
    coutryCodeArray=Object.entries(CountryData.id_to_countrycode);
    
    /* 國碼加入預設值+886 */
    if(!myform.countryCode){
        setMyform({ ...myform, countryCode: '+886' });
    }

    /* 時時記錄表單欄位值 */
    const changeFields = (event) => {
        event.preventDefault();
        setMyform({ ...myform, registerEmail: '' });
        const id = event.target.id;
        const val = event.target.value;
        // // console.log({ id, val });
        setMyform({ ...myform, [id]: val });
        setRegxpError('');
    }

    /* 登入手機驗證 */
    // const verifyPhone = () => {
    //     // 設定手機驗證格式
    //     let phoneRegxp = /^[1-9]{1}[0-9]{4,19}$/;
    //     let phoneRegxpWith0 = /^[0]+[0-9]{4,19}$/;
    //     // 手機驗證結果
    //     let phoneRegxpResult = phoneRegxp.test(myform.phone);
    //     let phoneRegxpResultWith0 = phoneRegxpWith0.test(myform.phone);
    //     // 若表單有自動填入值，則使用自動填入值驗證
    //     console.log('verifyPhone內:myform.phone',myform.phone)
    //     console.log('phoneRegxpResult',phoneRegxpResult)
    //     console.log('phoneRegxpResultWith0',phoneRegxpResultWith0)
    //     if( !! autoPhone.current ) {
    //       phoneRegxpResult = phoneRegxp.test( autoPhone.current.value );
    //       phoneRegxpResultWith0 = phoneRegxpWith0.test( autoPhone.current.value );
    //     }
    //     // 驗證失敗顯示格式錯誤訊息
    //     if( phoneRegxpResult === false && phoneRegxpResultWith0 === false ){
    //       return false;
    //     }
    //     return true;
    // }

    /* 驗證手機格式 */
    const verifyPhoneFormat = ( phoneArgs, phoneArgsWithCountryCode ) => {
      const verifyResult = true;
      // 驗證手機至少9碼
      // let phoneRegxp = /^[0-9]{1}[0-9]{2,19}$/g;
      let phoneRegxp = /^[0-9]{1}[0-9]{4,19}$/;

      let phoneRegxpResult = phoneRegxp.test( phoneArgs ); 
      // console.log('phoneArgs',phoneArgs);
      // 利用phone module驗證手機
      const veriPhoneObj = phone(phoneArgsWithCountryCode);
      // console.log('veriPhoneObj.isValid',veriPhoneObj.isValid);
      // console.log('phoneRegxpResult',phoneRegxpResult);
      if( ! veriPhoneObj.isValid || ! phoneRegxpResult ) {
          setRegxpError('請填寫正確手機資訊！');
          verifyResult = false;
          return verifyResult;
      } else {
        setRegxpError('');
      }
      return [verifyResult, veriPhoneObj.phoneNumber];
    }/* END 驗證手機格式 */

    /* 寄送SMS */
    const sendSMS = (event) => {
      setRegxpError(''); // 點選按鈕清空錯誤訊息 
      // console.log('sendSMS','sendSMS');
      event.preventDefault();
      // 格式化傳出之手機資料
      // 重整頁面後/使用者更動欄位 之手機值
      let phone = "";
      let phoneWithCountryCode = "";
      try {
        phone = myform.phone;
        phoneWithCountryCode = myform.countryCode + phone;
        // console.log('myform',myform);
        // console.log('phone',phone);
        // console.log('phoneWithCountryCode',phoneWithCountryCode);
      } catch (error) {
        console.log('error',error);
      }
      const verifyResult = verifyPhoneFormat( phone, phoneWithCountryCode )[0]; // 驗證手機格式
      // console.log('verifyResult',verifyResult);
      // 測試用
      // setVerifySMSBtn(false);
      // console.log('sendSMS verifySMSBtn',verifySMSBtn);
      // console.log('phone',phone);
      let data = {
        phone: phoneWithCountryCode,
      } 
      // console.log('data',data);
      const loginToken = window.localStorage.getItem('loginToken');
      // const BearerToken = 'Bearer ' + loginToken;
      // verifyResult = false;
      // console.log('verifyResult',verifyResult);
      // console.log('Timer', Timer);
      // 若手機驗證通過，POST送出手機資料，開始傳送簡訊驗證碼
      if( !! loginToken ) {
        if( verifyResult ) {
          // console.log('開始傳送簡訊驗證碼');
          // console.log('Timer', Timer);updateFormdata
          // TimerObj = new Timer;
          // TimerObj.startTimer(); // 開始計時
          // console.log('TimerObj.state.time.s', TimerObj.state.time.s);
          fetch('https://' + uaaApiURL + '/api/uaa/message/phone_verification_code/UnbindPhone', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
          })
          .then((r) => r.json())
          .then((result) => {
            // console.log('result', result);
            if ( result.statusCode === 200 ) {
              setHasSendSMS(true);
              setVerifySMSBtn(false); // 等60秒才能再送簡訊
              setRegxpError('簡訊驗證碼已傳送！');
            } else {
              setRegxpError('請填寫正確手機資訊！');
            }
          })
          .catch((error) => {
            setRegxpError('伺服器錯誤，請稍候再試！');
            console.log(error);
          }); // END Fetch
        } else {
          setRegxpError('錯誤的手機格式！');
        }
      } else {
        setRegxpError('無法寄送驗證碼（需要先登入）');
      }
    } /* END 寄送SMS */

    /* 監聽驗證碼，當點選驗證簡訊驗證碼，即開始驗證 */
    const submitSMS = (event) => {
      setRegxpError(''); // 點選按鈕清空錯誤訊息 
      // console.log('myform.sms',myform.sms);
      // console.log('smscode.current.value', smscode.current.value);
      if( smscode.current.value.length === 6 ) {
        verifySMS( smscode.current.value );
        setRegxpError('');
      } else {
        setRegxpError('請填入6碼簡訊驗證碼！');
      }
      return;
    } /* END 監聽驗證碼 */

    /* 驗證SMS */
    const verifySMS = ( sms ) => {
      setIsLoadingSMS(true);
      // 重整頁面後/使用者更動欄位 之手機值
      let phone = "";
      if( !! window.localStorage.getItem( 'phoneNumber' ) ) {
        phone = myform.countryCode + window.localStorage.getItem( 'phoneNumber' );
      } else {
        phone = myform.countryCode + myform.phone;
      }
      let data = {
        'phone': phone,
        'code': sms
      }
      const loginToken = window.localStorage.getItem('loginToken');
      // const BearerToken = 'Bearer ' + loginToken;
      // console.log('data', data);
      fetch('https://' + uaaApiURL + '/api/uaa/message/phone_verify/UnbindPhone', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then((r) => r.json())
      .then((result) => {
        if ( result.statusCode === 200 ) {
          setIsLoadingSMS(false);
          setSmsVeriStatus(true);
          setRegxpError('驗證成功！');
        } else {
          setIsLoadingSMS(false);
          setSmsVeriStatus(false);
          setRegxpError('錯誤的簡訊驗證碼！');
        } 
      })
      .catch((error) => {
        console.log('error',error);
        setIsLoadingSMS(false);
        setRegxpError('伺服器錯誤，請稍後再試！');
      });
    }  

    /* 60秒後將簡訊驗證按鈕打開 */
    useEffect(() => {
      let timer = "";
      const executeTimeout = async() => {
        if( ! verifySMSBtn ) {
          timer = setTimeout(() => {
            setVerifySMSBtn(true);
            // console.log('SetTimeout verifySMSBtn',verifySMSBtn);
          }, 60000);
        }
      }
      executeTimeout();
      return () => clearTimeout(timer);
    });

    /* 解除綁定 */
    const phoneUnlink = () => {
      // 重整頁面後/使用者更動欄位 之手機值
      let phone = "";
      let phoneWithCountryCode = "";
      try {
        phone = myform.phone;
        phoneWithCountryCode = myform.countryCode + phone;
        // console.log('myform',myform);
        // console.log('phone',phone);
        // console.log('phoneWithCountryCode',phoneWithCountryCode);
      } catch (error) {
        console.log('error',error);
      }
      const verifyResult = verifyPhoneFormat( phone, phoneWithCountryCode )[0]; // 驗證手機格式
      if( verifyResult ) {
        // 成功驗證簡訊，則可解除綁定
        if( smsVeriStatus ) {
          /*** 測試 ***/
          // setRegxpError('此手機號碼已解除綁定，您可用此手機號碼重新綁定新帳號！'); 
          // alert('此手機號碼已解除綁定，您可用此手機號碼重新綁定新帳號！');
          // window.location.reload();
          /*** END 測試 ***/
          const data = {
            'confirm': true,
            'phone': phoneWithCountryCode
          }
          const loginToken = window.localStorage.getItem('loginToken');
          //const BearerToken = 'Bearer ' + loginToken; // 'Authorization': BearerToken
          setIsLoadingPhoneUnlink(true); // 開始處理解除綁定
          fetch('https://' + uaaApiURL + '/api/uaa/phone/unbind', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json'
            },
          })
          .then((r) => r.json())
          .then((result) => {
            if ( result.statusCode === 200 ) {
              setIsLoadingPhoneUnlink(false); // 解除綁定結束
              setSmsVeriStatus(true);
              // window.localStorage.setItem('smsVeriStatus', 'true');
              setRegxpError('此手機號碼已解除綁定，您可用此手機號碼重新綁定新帳號！'); 
              alert('此手機號碼已解除綁定，您可用此手機號碼重新綁定新帳號！');
              window.location.assign( "/SubmitPage" );
            } else {
              setIsLoadingPhoneUnlink(false); // 解除綁定結束
              setSmsVeriStatus(false);
              // window.localStorage.setItem('smsVeriStatus', 'false');
              setRegxpError( 'Error ' + result.statusCode + '：此手機號碼無法解除綁定！' );
            }
          })
          .catch((error) => {
            // console.log('error',error);
            setIsLoadingPhoneUnlink(false); // 解除綁定結束
            setSmsVeriStatus(false);
            setRegxpError('伺服器錯誤，請稍後再試！');
          });
        } else {
          setIsLoadingPhoneUnlink(false); // 解除綁定結束
          setSmsVeriStatus(false);
          setRegxpError('手機必須先通過驗證才能解除綁定！');
        }
      } else {
        setRegxpError('錯誤的手機格式！');
      }
    }

    return (
      <Row>
          <Col>
              <Form className={phoneUnlinkPopupStyle.form} autoComplete="off">
                  <Col className="mb-3">
                      <div className={phoneUnlinkPopupStyle.KidProLoginTitleBox}>
                          <span className={phoneUnlinkPopupStyle.KidProLoginTitle}>手機解除綁定</span>
                      </div>
                  </Col>
                  <Col>
                      <Form.Group className="mb-4">
                          <div className={phoneUnlinkPopupStyle.LabelSwitchDiv}>
                              <Form.Label className={phoneUnlinkPopupStyle.LoginLabel}>手機號碼</Form.Label>
                          </div>
                          <div className={phoneUnlinkPopupStyle.PhoneSec}>
                              <select className={phoneUnlinkPopupStyle.countryCode} id="countryCode"  onChange={changeFields} value={myform.countryCode} required>
                                  {coutryCodeArray.map((v,i)=>{
                                      return(
                                          <>
                                              <option href="#/action-1" key={i+1}>{v[1]}</option>
                                          </>    
                                      )
                                  })}
                              </select>
                              <input className={phoneUnlinkPopupStyle.inputinfiled} type="tel" id="phone" name='phone' pattern="[0-9|\-|\s]{5,20}" ref={autoPhone} onChange={changeFields} value={myform.phone} placeholder='範例：910123456'  autoComplete="false" required/>
                          </div>
                      </Form.Group>
                      <Col className="mb-4">
                          <div className={phoneUnlinkPopupStyle.loginbuttosec}>
                          {/* <div>{'smsVeriStatus: '+JSON.stringify(smsVeriStatus)}</div>
                          <div>{'hasSendSMS: '+JSON.stringify(hasSendSMS)}</div> */}
                          { smsVeriStatus ? (
                              <>
                                  <Col className="mb-4">
                                  <div className={phoneUnlinkPopupStyle.loginbuttosec}>
                                      {isLoadingPhoneUnlink===true?(< LoadingSpinner></LoadingSpinner>):(<button type="submit" className={phoneUnlinkPopupStyle.loginbutton} onClick={phoneUnlink} >解除綁定</button>)}
                                  </div>
                                  </Col>
                              </>
                              )
                              : ! hasSendSMS ?
                              (  
                              <>
                                  <div className={phoneUnlinkPopupStyle.form_smsPadding}></div>
                                  <div className={phoneUnlinkPopupStyle.form_smsSec}>
                                  <div className={phoneUnlinkPopupStyle.form_buttonPhoneDiv}>
                                      {isLoading === true ? (< LoadingSpinner></LoadingSpinner>):(<button type="button" className={phoneUnlinkPopupStyle.loginbutton} onClick={sendSMS}>傳送驗證碼</button>)}
                                  </div>                       
                                  </div>
                              </>
                              )
                              :
                              (
                              <>
                                  <div className={phoneUnlinkPopupStyle.form_smsPadding}></div>
                                  <div className={phoneUnlinkPopupStyle.form_smsSec}>
                                  <div className={phoneUnlinkPopupStyle.form_buttonPhoneDiv}>
                                      { verifySMSBtn ? (
                                      isLoading === true ? (< LoadingSpinner></LoadingSpinner>):(<button type="button" className={phoneUnlinkPopupStyle.loginbutton} onClick={sendSMS}>傳送驗證碼</button>)
                                      ) : (
                                      isLoading === true ? (< LoadingSpinner></LoadingSpinner>):(<button type="button" className={phoneUnlinkPopupStyle.loginbutton} onClick={sendSMS} disabled><Timer></Timer></button>)
                                      ) }
                                  </div>
                                  <div id="veifySMSDiv"  className={phoneUnlinkPopupStyle.form_verifySMSDiv}>
                                      <Form.Group className="mb-4">
                                      <Form.Label className={phoneUnlinkPopupStyle.pdsLable}>簡訊驗證碼（6碼）</Form.Label>
                                      <input className={phoneUnlinkPopupStyle.inputinfiled} type="text" id="sms" name='sms' maxLength="6" ref={smscode} onChange={changeFields} value={myform.sms}/>               
                                      </Form.Group>
                                      <div className={phoneUnlinkPopupStyle.form_buttonPhoneDiv}>
                                      { isLoadingSMS ? (<LoadingSpinner></LoadingSpinner>):(<button type="button" className={phoneUnlinkPopupStyle.loginbutton} onClick={submitSMS}>開始驗證</button>)}
                                      </div>
                                  </div>                           
                                  </div>
                              </>
                              )
                          }
                          </div>
                      </Col>
                      <Col>
                          <div className={phoneUnlinkPopupStyle.RegxpErrorSec}>
                              <p className={phoneUnlinkPopupStyle.RegxpError}>{RegxpError}</p>
                          </div>
                      </Col>
                  </Col>
              </Form>
          </Col>
      </Row>
    )
}

export default FormPhoneUnlink

